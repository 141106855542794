import React, { useState, useEffect, useRef } from "react";
import { deleteExistingProject, updateModal, closeModal } from "../store/project.js";
import { useDispatch, useSelector } from "react-redux";
import "../style/Modal.css";

function DeleteProjectModal({ isOpen }) {
    const [confirmValue, setConfirmValue] = useState("");
    const [isDeletingInProgress, setIsDeletingInProgress] = useState(false);
    const [error, setError] = useState("");
    const modalRef = useRef(null);
    const dispatch = useDispatch();
    const { modal } = useSelector((state) => state.project);

    const title = `Delete Project: ${modal.data?.project_name || ''}`;
    const confirmText = "delete";

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target) &&
                !isDeletingInProgress
            ) {
                dispatch(updateModal({ name: '', data: null }));
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dispatch, isDeletingInProgress]);

    useEffect(() => {
        setConfirmValue("");
    }, [isOpen]);

    const handleDeleteSubmit = async (e) => {
        e.preventDefault();
        if (confirmValue.toLowerCase() !== confirmText.toLowerCase()) {
            setError(`Please type "${confirmText}" to confirm.`);
            return;
        }
        try {
            setIsDeletingInProgress(true);
            const deletedProjectId = modal.data.project_id;
            await dispatch(deleteExistingProject({ projectId: deletedProjectId })).unwrap();
            dispatch(closeModal());
        } catch (error) {
            console.error("Error deleting project:", error);
        } finally {
            setIsDeletingInProgress(false);
        }
    };

    return (
        <div 
            className={`delete-project-modal-overlay ${isDeletingInProgress ? 'deleting' : ''}`} 
            onClick={(e) => {
                if (!isDeletingInProgress) {
                    e.stopPropagation();
                }
            }}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-title"
        >
            <div 
                className="delete-project-modal-content" 
                ref={modalRef}
                role="document"
            >
                <h2 id="modal-title" className="delete-project-modal-title">{title}</h2>
                {error && (
                    <div 
                        className="modal-error-message" 
                        role="alert"
                        aria-live="polite"
                    >
                        {error}
                    </div>
                )}
                {isDeletingInProgress ? (
                    <div 
                        className="delete-project-loading"
                        role="status"
                        aria-live="polite"
                    >
                        <p>Deleting project. Please wait...</p>
                        <div 
                            className="delete-project-loading-spinner"
                            aria-hidden="true"
                        ></div>
                    </div>
                ) : (
                    <>
                        <p id="delete-instructions">Are you sure you want to delete this project? This action cannot be undone.</p>
                        <form 
                            onSubmit={handleDeleteSubmit}
                            aria-describedby="delete-instructions"
                        >
                            <input
                                id="confirm-delete"
                                type="text"
                                value={confirmValue}
                                onChange={(e) => setConfirmValue(e.target.value)}
                                placeholder={`Type "${confirmText}" to confirm`}
                                className="delete-project-input"
                                aria-invalid={error ? "true" : "false"}
                                aria-describedby={error ? "error-message" : undefined}
                            />
                            <div className="delete-project-modal-buttons">
                                <button 
                                    type="button" 
                                    onClick={() => dispatch(closeModal())} 
                                    className="delete-project-cancel-button"
                                >
                                    Cancel
                                </button>
                                <button 
                                    type="submit" 
                                    className="delete-project-submit-button"
                                >
                                    Delete
                                </button>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
}

export default DeleteProjectModal;
