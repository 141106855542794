export const ladders = {

    "NEB_1 kb Plus DNA Ladder": {
        "vendor": "NEB",
        "name": "1 kb Plus DNA Ladder",
        "link": "https://www.neb.com/en-us/products/n3200-1-kb-plus-dna-ladder",
        "fragments": [
            {
                "fragment_number": "1",
                "mass": "40",
                "bp": "10002"
            },
            {
                "fragment_number": "2",
                "mass": "40",
                "bp": "8001"
            },
            {
                "fragment_number": "3",
                "mass": "48",
                "bp": "6001"
            },
            {
                "fragment_number": "4",
                "mass": "40",
                "bp": "5001"
            },
            {
                "fragment_number": "5",
                "mass": "32",
                "bp": "4001"
            },
            {
                "fragment_number": "6",
                "mass": "120",
                "bp": "3001"
            },
            {
                "fragment_number": "7",
                "mass": "40",
                "bp": "2017"
            },
            {
                "fragment_number": "8",
                "mass": "57",
                "bp": "1517"
            },
            {
                "fragment_number": "9",
                "mass": "45",
                "bp": "1200"
            },
            {
                "fragment_number": "10",
                "mass": "122",
                "bp": "1000"
            },
            {
                "fragment_number": "11",
                "mass": "34",
                "bp": "900"
            },
            {
                "fragment_number": "12",
                "mass": "31",
                "bp": "800"
            },
            {
                "fragment_number": "13",
                "mass": "27",
                "bp": "700"
            },
            {
                "fragment_number": "14",
                "mass": "23",
                "bp": "600"
            },
            {
                "fragment_number": "15",
                "mass": "124",
                "bp": "500"
            },
            {
                "fragment_number": "16",
                "mass": "49",
                "bp": "400"
            },
            {
                "fragment_number": "17",
                "mass": "37",
                "bp": "300"
            },
            {
                "fragment_number": "18",
                "mass": "32",
                "bp": "200"
            },
            {
                "fragment_number": "19",
                "mass": "61",
                "bp": "100"
            }
        ]
    },
    "NEB_1 kb DNA Ladder": {
        "vendor": "NEB",
        "name": "1 kb DNA Ladder",
        "link": "https://www.neb.com/en-us/products/n3232-1-kb-dna-ladder",
        "fragments": [
            {
                "fragment_number": "1",
                "mass": "42",
                "bp": "10,002"
            },
            {
                "fragment_number": "2",
                "mass": "42",
                "bp": "8,001"
            },
            {
                "fragment_number": "3",
                "mass": "50",
                "bp": "6,001"
            },
            {
                "fragment_number": "4",
                "mass": "42",
                "bp": "5,001"
            },
            {
                "fragment_number": "5",
                "mass": "33",
                "bp": "4,001"
            },
            {
                "fragment_number": "6",
                "mass": "125",
                "bp": "3,001"
            },
            {
                "fragment_number": "7",
                "mass": "48",
                "bp": "2,000"
            },
            {
                "fragment_number": "8",
                "mass": "36",
                "bp": "1,500"
            },
            {
                "fragment_number": "9",
                "mass": "42",
                "bp": "1,000"
            },
            {
                "fragment_number": "10a",
                "mass": "21",
                "bp": "517"
            },
            {
                "fragment_number": "10b",
                "mass": "21",
                "bp": "500"
            }
        ]
    },
    "NEB_100 bp DNA Ladder": {
        "vendor": "NEB",
        "name": "100 bp DNA Ladder",
        "link": "https://www.neb.com/en-us/products/n3231-100-bp-dna-ladder",
        "fragments": [
            {
                "fragment_number": "1",
                "mass": "45",
                "bp": "1517"
            },
            {
                "fragment_number": "2",
                "mass": "35",
                "bp": "1200"
            },
            {
                "fragment_number": "3",
                "mass": "95",
                "bp": "1000"
            },
            {
                "fragment_number": "4",
                "mass": "27",
                "bp": "900"
            },
            {
                "fragment_number": "5",
                "mass": "24",
                "bp": "800"
            },
            {
                "fragment_number": "6",
                "mass": "21",
                "bp": "700"
            },
            {
                "fragment_number": "7",
                "mass": "18",
                "bp": "600"
            },
            {
                "fragment_number": "8",
                "mass": "97",
                "bp": "500"
            },
            {
                "fragment_number": "9",
                "mass": "38",
                "bp": "400"
            },
            {
                "fragment_number": "10",
                "mass": "29",
                "bp": "300"
            },
            {
                "fragment_number": "11",
                "mass": "25",
                "bp": "200"
            },
            {
                "fragment_number": "12",
                "mass": "48",
                "bp": "100"
            }
        ]
    },
    "NEB_50 bp DNA Ladder": {
        "vendor": "NEB",
        "name": "50 bp DNA Ladder",
        "link": "https://www.neb.com/en-us/products/n3236-50-bp-dna-ladder",
        "fragments": [
            {
                "fragment_number": "1",
                "mass": "103",
                "bp": "1350"
            },
            {
                "fragment_number": "2",
                "mass": "70",
                "bp": "916"
            },
            {
                "fragment_number": "3",
                "mass": "58",
                "bp": "766"
            },
            {
                "fragment_number": "4",
                "mass": "54",
                "bp": "700"
            },
            {
                "fragment_number": "5",
                "mass": "50",
                "bp": "650"
            },
            {
                "fragment_number": "6",
                "mass": "46",
                "bp": "600"
            },
            {
                "fragment_number": "7",
                "mass": "42",
                "bp": "550"
            },
            {
                "fragment_number": "8",
                "mass": "76",
                "bp": "500"
            },
            {
                "fragment_number": "9",
                "mass": "34",
                "bp": "450"
            },
            {
                "fragment_number": "10",
                "mass": "31",
                "bp": "400"
            },
            {
                "fragment_number": "11",
                "mass": "27",
                "bp": "350"
            },
            {
                "fragment_number": "12",
                "mass": "46",
                "bp": "300"
            },
            {
                "fragment_number": "13",
                "mass": "57",
                "bp": "250"
            },
            {
                "fragment_number": "14",
                "mass": "107",
                "bp": "200"
            },
            {
                "fragment_number": "15",
                "mass": "46",
                "bp": "150"
            },
            {
                "fragment_number": "16",
                "mass": "69",
                "bp": "100"
            },
            {
                "fragment_number": "17",
                "mass": "84",
                "bp": "50"
            }
        ]
    },
    "NEB_Low Molecular Weight DNA Ladder": {
        "vendor": "NEB",
        "name": "Low Molecular Weight DNA Ladder",
        "link": "https://www.neb.com/en-us/products/n3233-low-molecular-weight-dna-ladder",
        "fragments": [
            {
                "fragment_number": "1",
                "mass": "42",
                "bp": "766"
            },
            {
                "fragment_number": "2",
                "mass": "27",
                "bp": "500"
            },
            {
                "fragment_number": "3",
                "mass": "20",
                "bp": "350"
            },
            {
                "fragment_number": "4",
                "mass": "33",
                "bp": "300"
            },
            {
                "fragment_number": "5",
                "mass": "27",
                "bp": "250"
            },
            {
                "fragment_number": "6",
                "mass": "110",
                "bp": "200"
            },
            {
                "fragment_number": "7",
                "mass": "33",
                "bp": "150"
            },
            {
                "fragment_number": "8",
                "mass": "43",
                "bp": "100"
            },
            {
                "fragment_number": "9",
                "mass": "58",
                "bp": "75"
            },
            {
                "fragment_number": "10",
                "mass": "63",
                "bp": "50"
            },
            {
                "fragment_number": "11",
                "mass": "43",
                "bp": "25"
            }
        ]
    },
    "Thermo_GeneRuler 1 kb DNA Ladder ": {
        "vendor": "Thermo",
        "name": "GeneRuler 1 kb DNA Ladder ",
        "link": "https://assets.thermofisher.com/TFS-Assets/BID/Reference-Materials/generuler-dna-ladders-labaid.pdf",
        "fragments": [
            {
                "fragment_number": "1",
                "mass": "NA",
                "bp": "10000"
            },
            {
                "fragment_number": "2",
                "mass": "NA",
                "bp": "8000"
            },
            {
                "fragment_number": "3",
                "mass": "NA",
                "bp": "6000"
            },
            {
                "fragment_number": "4",
                "mass": "NA",
                "bp": "5000"
            },
            {
                "fragment_number": "5",
                "mass": "NA",
                "bp": "4000"
            },
            {
                "fragment_number": "6",
                "mass": "NA",
                "bp": "3000"
            },
            {
                "fragment_number": "7",
                "mass": "NA",
                "bp": "2000"
            },
            {
                "fragment_number": "8",
                "mass": "NA",
                "bp": "1500"
            },
            {
                "fragment_number": "9",
                "mass": "NA",
                "bp": "1000"
            },
            {
                "fragment_number": "10",
                "mass": "NA",
                "bp": "750"
            },
            {
                "fragment_number": "11",
                "mass": "NA",
                "bp": "500"
            },
            {
                "fragment_number": "12",
                "mass": "NA",
                "bp": "250"
            }
        ]
    },
    "Thermo_GeneRuler 1 kb Plus DNA Ladder": {
        "vendor": "Thermo",
        "name": "GeneRuler 1 kb Plus DNA Ladder",
        "link": "https://assets.thermofisher.com/TFS-Assets/BID/Reference-Materials/generuler-dna-ladders-labaid.pdf",
        "fragments": [
            {
                "fragment_number": "1",
                "mass": "NA",
                "bp": "20000"
            },
            {
                "fragment_number": "2",
                "mass": "NA",
                "bp": "10000"
            },
            {
                "fragment_number": "3",
                "mass": "NA",
                "bp": "7000"
            },
            {
                "fragment_number": "4",
                "mass": "NA",
                "bp": "5000"
            },
            {
                "fragment_number": "5",
                "mass": "NA",
                "bp": "4000"
            },
            {
                "fragment_number": "6",
                "mass": "NA",
                "bp": "3000"
            },
            {
                "fragment_number": "7",
                "mass": "NA",
                "bp": "2000"
            },
            {
                "fragment_number": "8",
                "mass": "NA",
                "bp": "1500"
            },
            {
                "fragment_number": "9",
                "mass": "NA",
                "bp": "1000"
            },
            {
                "fragment_number": "10",
                "mass": "NA",
                "bp": "700"
            },
            {
                "fragment_number": "11",
                "mass": "NA",
                "bp": "500"
            },
            {
                "fragment_number": "12",
                "mass": "NA",
                "bp": "400"
            },
            {
                "fragment_number": "13",
                "mass": "NA",
                "bp": "300"
            },
            {
                "fragment_number": "14",
                "mass": "NA",
                "bp": "200"
            },
            {
                "fragment_number": "15",
                "mass": "NA",
                "bp": "75"
            }
        ]
    },
    "Thermo_GeneRuler 100 bp DNA Ladder": {
        "vendor": "Thermo",
        "name": "GeneRuler 100 bp DNA Ladder",
        "link": "https://assets.thermofisher.com/TFS-Assets/BID/Reference-Materials/generuler-dna-ladders-labaid.pdf",
        "fragments": [
            {
                "fragment_number": "1",
                "mass": "NA",
                "bp": "1000"
            },
            {
                "fragment_number": "2",
                "mass": "NA",
                "bp": "900"
            },
            {
                "fragment_number": "3",
                "mass": "NA",
                "bp": "800"
            },
            {
                "fragment_number": "4",
                "mass": "NA",
                "bp": "700"
            },
            {
                "fragment_number": "5",
                "mass": "NA",
                "bp": "600"
            },
            {
                "fragment_number": "6",
                "mass": "NA",
                "bp": "500"
            },
            {
                "fragment_number": "7",
                "mass": "NA",
                "bp": "400"
            },
            {
                "fragment_number": "8",
                "mass": "NA",
                "bp": "300"
            },
            {
                "fragment_number": "9",
                "mass": "NA",
                "bp": "200"
            },
            {
                "fragment_number": "10",
                "mass": "NA",
                "bp": "100"
            }
        ]
    },
    "Thermo_GeneRuler 100 bp Plus DNA Ladder": {
        "vendor": "Thermo",
        "name": "GeneRuler 100 bp Plus DNA Ladder",
        "link": "https://assets.thermofisher.com/TFS-Assets/BID/Reference-Materials/generuler-dna-ladders-labaid.pdf",
        "fragments": [
            {
                "fragment_number": "1",
                "mass": "NA",
                "bp": "3000"
            },
            {
                "fragment_number": "2",
                "mass": "NA",
                "bp": "2000"
            },
            {
                "fragment_number": "3",
                "mass": "NA",
                "bp": "1500"
            },
            {
                "fragment_number": "4",
                "mass": "NA",
                "bp": "1200"
            },
            {
                "fragment_number": "5",
                "mass": "NA",
                "bp": "1000"
            },
            {
                "fragment_number": "6",
                "mass": "NA",
                "bp": "900"
            },
            {
                "fragment_number": "7",
                "mass": "NA",
                "bp": "800"
            },
            {
                "fragment_number": "8",
                "mass": "NA",
                "bp": "700"
            },
            {
                "fragment_number": "9",
                "mass": "NA",
                "bp": "600"
            },
            {
                "fragment_number": "10",
                "mass": "NA",
                "bp": "500"
            },
            {
                "fragment_number": "11",
                "mass": "NA",
                "bp": "400"
            },
            {
                "fragment_number": "12",
                "mass": "NA",
                "bp": "300"
            },
            {
                "fragment_number": "13",
                "mass": "NA",
                "bp": "200"
            },
            {
                "fragment_number": "14",
                "mass": "NA",
                "bp": "100"
            }
        ]
    },
    "Thermo_GeneRuler 50 bp DNA Ladder": {
        "vendor": "Thermo",
        "name": "GeneRuler 50 bp DNA Ladder",
        "link": "https://assets.thermofisher.com/TFS-Assets/BID/Reference-Materials/generuler-dna-ladders-labaid.pdf",
        "fragments": [
            {
                "fragment_number": "1",
                "mass": "NA",
                "bp": "1000"
            },
            {
                "fragment_number": "2",
                "mass": "NA",
                "bp": "900"
            },
            {
                "fragment_number": "3",
                "mass": "NA",
                "bp": "800"
            },
            {
                "fragment_number": "4",
                "mass": "NA",
                "bp": "700"
            },
            {
                "fragment_number": "5",
                "mass": "NA",
                "bp": "600"
            },
            {
                "fragment_number": "6",
                "mass": "NA",
                "bp": "500"
            },
            {
                "fragment_number": "7",
                "mass": "NA",
                "bp": "400"
            },
            {
                "fragment_number": "8",
                "mass": "NA",
                "bp": "300"
            },
            {
                "fragment_number": "9",
                "mass": "NA",
                "bp": "250"
            },
            {
                "fragment_number": "10",
                "mass": "NA",
                "bp": "200"
            },
            {
                "fragment_number": "11",
                "mass": "NA",
                "bp": "150"
            },
            {
                "fragment_number": "12",
                "mass": "NA",
                "bp": "100"
            },
            {
                "fragment_number": "13",
                "mass": "NA",
                "bp": "50"
            }
        ]
    }
}
