import React, { useState, useCallback, useEffect, useRef } from "react";
import "../style/ArtifactPanel.css";
import { useSelector, useDispatch } from "react-redux";
import {
  generateProjectInstructions,
  clearGenerateInstructionsError,
  updateArtifact,
  navigateDesign,
} from "../store/project.js";
import { BiFullscreen, BiExitFullscreen } from "react-icons/bi";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Design from "./Design.js";
import Protocol from "./Protocol.js";
import Sequence from "./Sequence.js";

function ArtifactPanel({ onClose }) {
  const artifact = useSelector((state) => state.project.artifact);
  const projectId = useSelector((state) => state.project.currentProjectId);
  const isSidebarVisible = useSelector((state) => state.project.isSidebarVisible);
  const [width, setWidth] = useState(61);
  const [isDragging, setIsDragging] = useState(false);
  const design = useSelector((state) => state.project.design);
  const dispatch = useDispatch();
  const [messagesWidth, setMessagesWidth] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const panelRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to control popup visibility
  const isArtifactLoading = useSelector((state) => state.project.isArtifactLoading);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (isArtifactLoading) {
      // Wait 300ms before showing loading spinner
      timeoutId = setTimeout(() => {
        setShowLoading(true);
      }, 300);
    } else {
      setShowLoading(false);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isArtifactLoading]);

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      const mainElement = document.querySelector(".main");
      if (mainElement) {
        mainElement.classList.add("panel-closed");
        mainElement.style.width = "0";
      }
      setIsFullscreen(!isFullscreen);
    } else {
      const mainElement = document.querySelector(".main");
      if (mainElement) {
        mainElement.classList.remove("panel-closed");
        mainElement.style.width = "";
      }
      setIsFullscreen(!isFullscreen);
    }
  };

  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleMouseMove = useCallback(
    (e) => {
      if (isDragging) {
        const newWidth =
          ((window.innerWidth - e.clientX) / window.innerWidth) * 100 +
          (isSidebarVisible ? 10 : 0);
        setWidth(Math.max(20, Math.min(80, newWidth)));
      }
    },
    [isDragging, isSidebarVisible]
  );

  useEffect(() => {
    const chatContainer = document.querySelector(".chat-messages-container");
    if (chatContainer) {
      setMessagesWidth(chatContainer.offsetWidth);
    }
  }, []);

  const onCloseButtonClick = () => {
    if (isFullscreen) {
      toggleFullscreen();
    }
    onClose();
  };

  useEffect(() => {
    if (isDragging) {
      const chatContainer = document.querySelector(".chat-messages-container");
      if (chatContainer && chatContainer.offsetWidth !== messagesWidth) {
        // scrollToBottom();
        setMessagesWidth(chatContainer.offsetWidth);
      }
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp, messagesWidth]);

  if (!artifact) {
    return null;
  }

  const handleConfirmGenerateInstructions = () => {
    setIsPopupOpen(false); // Hide the popup
    dispatch(clearGenerateInstructionsError());
    dispatch(
      generateProjectInstructions({ projectId, override: "false" })
    ); 
  };

  const handleCancelGenerateInstructions = () => {
    setIsPopupOpen(false); 
  };

  const handleDesignClick = () => {
    if (design) {
      dispatch(updateArtifact({ ...design, isDesign: true }));
    }
  };

  const handleForwardClick = () => {
    dispatch(navigateDesign({ projectId: projectId, designId: design.labkick_id, direction: 'forward' }));
  };

  const handleBackClick = () => {
    dispatch(navigateDesign({ projectId: projectId, designId: design.labkick_id, direction: 'back' }));
  };


  let content;
  console.log(artifact.type);
  if (artifact.type === "html") {
    content = (
      <div
        className="html-content"
        dangerouslySetInnerHTML={{ __html: artifact.content }}
      />
    );
  } else if (artifact.isDesign) {
    content = <Design />;
  } else if (artifact.type === "markdown" || artifact.type === "csv") {
    content = <Protocol />;
  } else {
    content = <Sequence />;
  }

  return (
    <div
      ref={panelRef}
      className={`artifact-panel ${isFullscreen ? "fullscreen-panel" : ""}`}
      style={{ flexBasis: `${width}%`, maxWidth: `${width}%` }}
      role="complementary"
      aria-label="Artifact viewer panel"
    >
      {isArtifactLoading && showLoading && (
        <div className={`artifact-loading-overlay ${showLoading ? 'visible' : ''}`} aria-label="Loading artifact">
          <div className="artifact-loading-spinner" role="progressbar" />
        </div>
      )}
      <div 
        className="resize-handle" 
        onMouseDown={handleMouseDown}
        role="separator"
        aria-label="Resize panel"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleMouseDown(e);
          }
        }}
      ></div>
      <div className="artifact-buttons-container">
        <button 
          className="fullscreen-button" 
          onClick={toggleFullscreen}
          aria-label={isFullscreen ? "Exit fullscreen" : "Enter fullscreen"}
        >
          {isFullscreen ? (
            <BiExitFullscreen size={24} aria-hidden="true" />
          ) : (
            <BiFullscreen size={24} aria-hidden="true" />
          )}
        </button>
      </div>
      {artifact && !artifact.isDesign && (
        <button 
          className="back-to-design-button" 
          onClick={handleDesignClick}
          aria-label="Return to design view"
        >
          <FaArrowLeft size={24} aria-hidden="true" />
          <span>Back to Design</span>
        </button>
      )}
      {artifact && artifact.isDesign && isFullscreen && (
        <button 
          className="back-to-design-button" 
          onClick={toggleFullscreen}
          aria-label="Return to chat view"
        >
          <FaArrowLeft size={24} aria-hidden="true" />
          <span>Back to Chat</span>
        </button>
      )}
      <button 
        className="close-button no-hover" 
        onClick={onCloseButtonClick}
        aria-label="Close panel"
      >
        &times;
      </button>

      {isPopupOpen && (
        <div 
          className="artifact-confirmation-popup-overlay"
          role="dialog"
          aria-modal="true"
          aria-labelledby="popup-title"
        >
          <div className="artifact-confirmation-popup-dialog">
            <h3 id="popup-title">Generate Instructions</h3>
            <p>
              Are you ready to generate instructions? Once you generate
              instructions you will only be able to modify the design twice.
              Please make sure you have all your parts you need as part of
              your design.
            </p>
            <div className="artifact-confirmation-popup-buttons">
              <button
                onClick={handleConfirmGenerateInstructions}
                className="artifact-confirm-button"
                aria-label="Confirm generate instructions"
              >
                Yes, Generate
              </button>
              <button
                onClick={handleCancelGenerateInstructions}
                className="artifact-cancel-button"
                aria-label="Cancel generate instructions"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <div 
        className="artifact-content"
        role="region"
        aria-label="Artifact content"
      >
        {content}
      </div>
    </div>
  );
}

export default ArtifactPanel;