import React, { useState, useEffect } from "react";
import Sidebar from "./components/Sidebar.js";
import ChatInput from "./components/ChatInput.js";
import ChatMessages from "./components/ChatMessages.js";
import ArtifactPanel from "./components/ArtifactPanel.js";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchUser,
  fetchCredential,
  populateSampleData,
  setPopupMessage, // Add this import
} from "./store/user.js";
import {
  fetchProjects,
  fetchChat,
  resetChat,
  updateArtifact,
  updateModal,
} from "./store/project.js";
import CreateProjectModal from "./components/CreateProjectModal.js";
import RenameProjectModal from "./components/RenameProjectModal.js";
import DeleteProjectModal from "./components/DeleteProjectModal.js";
import FileUploadModal from "./components/FileUploadModal.js";
import DeleteFileFromProjectModal from "./components/DeleteFileFromProjectModal.js";
import { BiPencil, BiHelpCircle } from "react-icons/bi"; // Add this import
import OrderDNA from "./components/OrderDNA.js";
import { useAuth0 } from "@auth0/auth0-react";

// TODO display DNA in artifact and make non-DNA artifacts not show

const HelpMenu = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const handleItemClick = (url, isEmail = false) => {
    if (isEmail) {
      window.location.href = `mailto:${url}`;
    } else {
      window.open(url, '_blank');
    }
    onClose();
  };

  return (
    <div 
      className="help-menu" 
      role="menu" 
      aria-label="Help menu"
    >
      <button
        className="help-menu-item"
        role="menuitem"
        onClick={() => handleItemClick('https://www.labkick.ai/terms_of_service_labkick.html')}
      >
        Terms of Service
      </button>
      <button
        className="help-menu-item"
        role="menuitem"
        onClick={() => handleItemClick('https://www.labkick.ai/privacy_policy_labkick.html')}
      >
        Privacy Policy
      </button>
      <button
        className="help-menu-item"
        role="menuitem"
        onClick={() => handleItemClick('support@labkick.ai', true)}
      >
        Contact Us
      </button>
      <button
        className="help-menu-item"
        role="menuitem"
        onClick={() => handleItemClick('https://www.labkick.ai/tutorial')}
      >
        Tutorial
      </button>
      <button
        className="help-menu-item"
        role="menuitem"
        onClick={() => handleItemClick('https://www.labkick.ai/appnotes')}
      >
        App Notes
      </button>
    </div>
  );
};

function App() {
  const dispatch = useDispatch();
  // const {
  //   isAuthenticated,
  // } = useAuth0();

  const { artifact, currentProjectId, modal, projectList } = useSelector(
    (state) => state.project
  );

  const chat = useSelector((state) => state.project.chat[currentProjectId]);

  const isSidebarVisible = useSelector(
    (state) => state.project.isSidebarVisible
  );

  const token = useSelector((state) => state.user.access_token);

  const isChatLoading = useSelector((state) => state.project.isChatLoading);

  const userId = useSelector((state) => state.user.user_id);

  const handleSampleClick = (sampleNumber) => {
    console.log("sample click userId", userId);
    dispatch(populateSampleData({ userId, sampleNumber }));
  };

  const isLoadingFiles = useSelector((state) => state.project.isLoadingFiles);
  const loadingMessage = useSelector((state) => state.project.loadingMessage);
  const isGeneratingInstructions = useSelector(
    (state) => state.project.isGeneratingInstructions
  );
  const instructionsLoadingMessage = useSelector(
    (state) => state.project.instructionsLoadingMessage
  );

  const design = useSelector((state) => state.project.design);

  console.log(
    "App rendering, modal:",
    modal,
    "isLoadingFiles:",
    isLoadingFiles,
    "loadingMessage:",
    loadingMessage
  );

  const handleDesignClick = () => {
    if (design) {
      dispatch(updateArtifact({ ...design, isDesign: true }));
    }
  };

  const popupMessage = useSelector((state) => state.user.popupMessage);

  // Add this new state to store the article ID
  const [articleId, setArticleId] = useState(null);

  useEffect(() => {
    // Check URL for article ID
    const searchParams = new URLSearchParams(window.location.search);
    const articleParam = Object.keys(Object.fromEntries(searchParams)).find(key => key.startsWith('article_'));

    if (articleParam) {
      const id = articleParam.split('_')[1];
      localStorage.setItem('pendingArticleId', id);
      setArticleId(id);
    } else {
      // Check localStorage for pending article ID
      const pendingId = localStorage.getItem('pendingArticleId');
      if (pendingId) {
        setArticleId(pendingId);
      }
    }
  }, []); // This effect runs once on mount

  useEffect(() => {
    if (articleId && token) {
      console.log(`Logged in user, Article ID: ${articleId}`);
      dispatch(populateSampleData({ userId, sampleNumber: 1 }));

      // Clear the pending article ID from localStorage
      localStorage.removeItem('pendingArticleId');

      // Reset articleId to prevent re-triggering
      if (userId) {
        setArticleId(null);
      }
    } else if (articleId) {
      console.log(`Not logged in, Article ID: ${articleId}`);
    }
  }, [articleId, token, userId, dispatch]); // This effect runs when articleId or token changes

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("success") === "true") {
      dispatch(setPopupMessage("Payment succeeded! You will receive an email confirmation."));
      removeQueryParams();
    } else if (searchParams.get("canceled") === "true") {
      dispatch(setPopupMessage("Payment failed or was canceled. Please try again."));
      removeQueryParams();
    }
  }, [dispatch]);

  const removeQueryParams = () => {
    const url = new URL(window.location);
    url.searchParams.delete("success");
    url.searchParams.delete("canceled");
    window.history.replaceState({}, document.title, url.pathname);
  };

  // Add this useEffect to auto-dismiss successful messages
  useEffect(() => {
    if (popupMessage && !popupMessage.includes("failed") && !popupMessage.includes("Error")) {
      const timer = setTimeout(() => {
        dispatch(setPopupMessage(null));
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [popupMessage, dispatch]);

  // Add this state near the top of the App function with other state declarations
  const [isHelpMenuOpen, setIsHelpMenuOpen] = useState(false);

  // Modify the useEffect for click handling
  useEffect(() => {
    const handleMouseLeave = (event) => {
      const helpContainer = document.querySelector('.help-container');
      if (helpContainer && !helpContainer.contains(event.relatedTarget)) {
        setIsHelpMenuOpen(false);
      }
    };

    const helpContainer = document.querySelector('.help-container');
    if (helpContainer) {
      helpContainer.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (helpContainer) {
        helpContainer.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, []);

  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const userStatus = useSelector((state) => state.user.status);
  const projectStatus = useSelector((state) => state.project.status);
  const isCheckingAuth = useSelector((state) => state.user.isCheckingAuth);


  useEffect(() => {
    console.log("not isCheckingAuth", isCheckingAuth, userStatus, projectStatus, isInitialLoading);

    if (!isCheckingAuth) {

      if (userStatus === 'succeeded' && projectStatus === 'succeeded') {
        // If user is logged in and all data is loaded, stop loading
        console.log("userStatus is succeeded and projectStatus is succeeded");
        setIsInitialLoading(false);
      }
    }
    else if (userStatus === 'unauthenticated') {
      console.log("userStatus is unauthenticated");

      // If user is logged in and all data is loaded, stop loading
      setIsInitialLoading(false);
    }


  }, [isCheckingAuth, token, userStatus, projectStatus]);

  const handleKeyPress = (e) => {
    if (e.key === 'Escape') {
      setIsHelpMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, []);

  return (
    <div
      className={`container ${isSidebarVisible ? "" : "sidebar-collapsed"} ${isLoadingFiles ? "loading" : ""}`}
      role="main"
    >
      <Sidebar />
      {isInitialLoading && (
        <div 
          className="initial-loading-overlay"
          role="alert"
          aria-busy="true"
        >
          <div className="loading-spinner" aria-label="Loading content"></div>
        </div>
      )}
      <div className="main-content-wrapper">
        <section className={`main ${artifact ? "with-artifact" : ""}`}>
          {(!isInitialLoading) && projectList.length === 0 ? (
            <div className="empty-chat-container" role="region" aria-label="Welcome section">
              <img
                src="/images/K-mark.svg"
                width={135}
                height={135}
                alt="LabKick Logo"
              />
              <h1>LabKick</h1>
              <h3>
                {token
                  ? "Welcome! Click a button below to get kicking."
                  : articleId
                    ? "Please sign up or log in to view the Project"
                    : "Please sign up or log in to get kicking!"}
              </h3>
              {token && (
                <div className="large-buttons-container">
                  <button
                    className="large-button"
                    onClick={() => dispatch(updateModal({ name: "create_project", data: null }))}
                    aria-label="Create new project"
                  >
                    <img
                      src="/images/new_project.svg"
                      alt=""
                      className="button-icon"
                      aria-hidden="true"
                    />
                    Create Project
                  </button>
                  <button
                    className="large-button"
                    onClick={() => handleSampleClick(1)}
                    aria-label="Tutorial"
                  >
                    <img
                      src="/images/key.svg"
                      alt=""
                      className="button-icon"
                      aria-hidden="true"
                    />
                    Tutorial
                  </button>
                  <button
                    className="large-button"
                    onClick={() => handleSampleClick(2)}
                    aria-label="Example"
                  >
                    <img
                      src="/images/dna-archive-fix.svg"
                      alt=""
                      className="button-icon"
                      aria-hidden="true"
                    />
                    Example
                  </button>
                </div>
              )}
            </div>
          ) : (
            <>
              <ChatMessages />
              <div className="input-design-container">
                <ChatInput />
              </div>
            </>
          )}
        </section>
        <ArtifactPanel onClose={() => dispatch(updateArtifact(null))} />
      </div>
      {modal.name === "create_project" && <CreateProjectModal />}
      {modal.name === "rename_project" && <RenameProjectModal />}
      {modal.name === "delete_project" && <DeleteProjectModal />}
      {(modal.name === "upload_general" || modal.name === "upload_project") && (
        <FileUploadModal />
      )}
      {modal.name === "delete_file" && (
        <DeleteFileFromProjectModal isOpen={true} />
      )}
      {modal.name === "order_dna" && <OrderDNA />}
      {isLoadingFiles && (
        <div 
          className="loading-overlay"
          role="alert"
          aria-busy="true"
        >
          <div className="loading-message">
            <p>{loadingMessage || "Uploading files..."}</p>
          </div>
        </div>
      )}
      {isGeneratingInstructions && (
        <div className="loading-overlay">
          <div className="loading-message">
            <p>{instructionsLoadingMessage || "Generating instructions..."}</p>
          </div>
        </div>
      )}
      {popupMessage && (
        <div 
          className="popup-message"
          role="alert"
          aria-live="polite"
        >
          <p>{popupMessage}</p>
          <button 
            onClick={() => dispatch(setPopupMessage(null))}
            aria-label="Close message"
          >×</button>
        </div>
      )}
      <div className="help-container">
        <button
          className="help-button"
          onClick={() => setIsHelpMenuOpen(!isHelpMenuOpen)}
          aria-expanded={isHelpMenuOpen}
          aria-haspopup="menu"
          aria-label="Help menu"
        >
          <BiHelpCircle size={24} color="white" aria-hidden="true" />
        </button>
        <HelpMenu isOpen={isHelpMenuOpen} onClose={() => setIsHelpMenuOpen(false)} />
      </div>
    </div>
  );
}

export default App;
