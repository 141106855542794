import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateModal, submitOrder } from '../store/project.js';
import { setPopupMessage } from '../store/user.js';
import '../style/OrderDNA.css';

function OrderDNA() {
  const dispatch = useDispatch();
  const modalData = useSelector((state) => state.project.modal.data);
  const currentProjectId = useSelector((state) => state.project.currentProjectId);
  const [sequenceReplicates, setSequenceReplicates] = useState(1);

  const handleClose = () => {
    dispatch(updateModal({ name: null, data: null }));
  };

  const handleExecute = () => {
    dispatch(submitOrder({
      projectId: currentProjectId,
      orderData: modalData,
      sequenceReplicates
    }))
    .unwrap()
    .then(() => {
      dispatch(setPopupMessage("Request submitted. The LabKick team will be in contact with you via email shortly"));
      handleClose();
    })
    .catch((error) => {
      dispatch(setPopupMessage("Failed to submit order request. Please try again."));
      handleClose();
    });
  };

  const getSequencingCost = () => {
    switch(sequenceReplicates) {
      case 0: return 0;
      case 1: return Math.round(modalData.sequencing_cost_one) || 0;
      case 2: return Math.round(modalData.sequencing_cost_two) || 0;
      case 3: return Math.round(modalData.sequencing_cost_three) || 0;
      default: return Math.round(modalData.sequencing_cost_one) || 0;
    }
  };

  const totalPrice = Math.round(
    (modalData.synthesis_cost || 0) + 
    (modalData.labor_cost || 0) + 
    (modalData.protocol_cost || 0) + 
    getSequencingCost()
  );

  const getPricePerConstruct = () => {
    const total = (modalData.synthesis_cost || 0) + 
                 (modalData.labor_cost || 0) + 
                 (modalData.protocol_cost || 0) + 
                 getSequencingCost();
    return (total / (modalData?.total_constructs || 1)).toFixed(0);
  };

  // Add keyboard handler for modal close
  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      handleClose();
    }
  };

  return (
    <div role="dialog" 
         aria-modal="true" 
         aria-labelledby="order-form-title"
         onKeyDown={handleKeyDown}>
      <div className="order-dna-overlay" onClick={handleClose} />
      <div className="order-dna-modal">
        <div className="order-dna-header">
          <h2 id="order-form-title">Order Form</h2>
          <button 
            className="order-dna-close" 
            onClick={handleClose}
            aria-label="Close order form">×</button>
        </div>
        
        <div className="order-dna-content">
          <div className="order-dna-form" role="form" aria-label="DNA Order Form">
            <div className="order-dna-process">
              <h3 className="order-dna-process-title">How it works</h3>
              <div className="order-dna-process-steps" role="list">
                <div className="order-dna-process-step" role="listitem">
                  <span className="order-dna-step-number">1</span>
                  <span className="order-dna-step-text">Submit Order</span>
                  <span className="order-dna-step-description">Customer submits order through LabKick platform</span>
                </div>
                <span className="order-dna-step-arrow">→</span>
                <div className="order-dna-process-step" role="listitem">
                  <span className="order-dna-step-number">2</span>
                  <span className="order-dna-step-text">Send Input DNA</span>
                  <span className="order-dna-step-description">Customer sends any DNA not being synthesized to LabKick</span>
                </div>
                <span className="order-dna-step-arrow">→</span>
                <div className="order-dna-process-step" role="listitem">
                  <span className="order-dna-step-number">3</span>
                  <span className="order-dna-step-text">Lab Work & Video</span>
                  <span className="order-dna-step-description">LabKick receives customer DNA, orders synthesis DNA, executes assembly</span>
                </div>
                <span className="order-dna-step-arrow">→</span>
                <div className="order-dna-process-step" role="listitem">
                  <span className="order-dna-step-number">4</span>
                  <span className="order-dna-step-text">Sequence Verify</span>
                  <span className="order-dna-step-description">LabKick sequence verifies all output DNA, shares video and data</span>
                </div>
                <span className="order-dna-step-arrow">→</span>
                <div className="order-dna-process-step" role="listitem">
                  <span className="order-dna-step-number">5</span>
                  <span className="order-dna-step-text">Return DNA</span>
                  <span className="order-dna-step-description">LabKick sends DNA constructs to customer</span>
                </div>
              </div>
            </div>

            <div className="order-dna-summary">
              <div className="order-dna-summary-item" role="group" aria-label="Order Summary">
                <span id="total-constructs-label">Total Constructs</span>
                <span className="order-dna-summary-value-light" aria-labelledby="total-constructs-label">
                  {modalData?.total_constructs || 0}
                </span>
              </div>

              <div className="order-dna-summary-item">
                <div className="order-dna-cost-line">
                  <span>Synthesis Cost <span className="order-dna-cost-per-bp">(${((modalData?.synthesis_cost || 0) / (modalData?.total_synthesis_bp || 1)).toFixed(2)}/bp)</span></span>
                  <span className="order-dna-summary-value-light">${Math.round(modalData?.synthesis_cost) || 0}</span>
                </div>
              </div>

              <div className="order-dna-summary-item">
                <span>Assembly Cost</span>
                <span className="order-dna-summary-value-light">
                  ${Math.round((modalData?.labor_cost || 0) + (modalData?.protocol_cost || 0))}
                </span>
              </div>

              <div className="order-dna-summary-item">
                <div className="order-dna-sequence-control">
                  <div className="order-dna-sequence-left">
                    <span id="sequence-replicates-label">Sequence Verify Replicates</span>
                    <div className="order-dna-replicate-selector" 
                         role="radiogroup" 
                         aria-labelledby="sequence-replicates-label">
                      {[0, 1, 2, 3].map((num) => (
                        <button
                          key={num}
                          className={`order-dna-replicate-button ${sequenceReplicates === num ? 'active' : ''}`}
                          onClick={() => setSequenceReplicates(num)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              setSequenceReplicates(num);
                            }
                          }}
                          role="radio"
                          aria-checked={sequenceReplicates === num}
                          tabIndex={0}
                        >
                          {num}
                        </button>
                      ))}
                    </div>
                  </div>
                  <span className="order-dna-summary-value-light" 
                        aria-label="Sequencing cost">
                    ${getSequencingCost()}
                  </span>
                </div>
              </div>

              <div className="order-dna-total">
                <div className="order-dna-total-row">
                  <div className="order-dna-cost-line">
                    <span>Total Price <span className="order-dna-cost-per-bp">(${getPricePerConstruct()}/construct)</span></span>
                    <span className="order-dna-summary-value-bold">${totalPrice}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="order-dna-beta-message" role="alert">
              {modalData?.beta_message}
            </div>
          </div>
        </div>

        <div className="order-dna-footer">
          <button 
            className="order-dna-button order-dna-cancel" 
            onClick={handleClose}
          >
            Cancel
          </button>
          <button 
            className="order-dna-button order-dna-submit" 
            onClick={handleExecute}
          >
            Submit Request
          </button>
        </div>
      </div>
    </div>
  );
}

export default OrderDNA;


