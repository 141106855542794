import React, { useState, useEffect, useRef } from "react";
import { createNewProject, updateModal, closeModal } from "../store/project.js";
import { useDispatch, useSelector } from "react-redux";
import { populateSampleData } from "../store/user.js";
import "../style/Modal.css";

function CreateProjectModal({ isOpen, initialValue = "" }) {
    const [inputValue, setInputValue] = useState(initialValue || "");
    const [error, setError] = useState("");
    const modalRef = useRef(null);
    const dispatch = useDispatch();
    const projectList = useSelector((state) => state.project.projectList);
    const userId = useSelector((state) => state.user.user_id);

    const title = "Create New Project";

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target)
            ) {
                dispatch(updateModal({ name: '', data: null }));
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dispatch]);

    useEffect(() => {
        setInputValue(initialValue || "");
    }, [initialValue]);

    const handleCreateProjectSubmit = (projectName) => {
        if (projectName.length > 20) {
            setError("Project name cannot exceed 20 characters");
            return;
        }

        const existingProject = projectList.find(project => project.project_name === projectName);
        if (existingProject) {
            setError("Project with this name already exists");
            return;
        }

        if (projectList.length >= 50) {
            setError("Maximum number of projects hit. Please delete some to create a new one.");
            return;
        }
        dispatch(createNewProject(projectName));
        dispatch(closeModal());
    };

    const handleSampleClick = (sampleNumber) => {
        dispatch(populateSampleData({ userId, sampleNumber }));
        dispatch(closeModal());
    };

    const handleAppNotesClick = () => {
        window.open('https://www.labkick.ai/appnotes', '_blank');
    };

    return (
        <div 
            className="create-project-modal-overlay" 
            onClick={(e) => e.stopPropagation()}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-title"
        >
            <div 
                className="create-project-modal-content" 
                ref={modalRef}
                role="document"
            >
                <h2 id="modal-title" className="create-project-modal-title">{title}</h2>
                {error && (
                    <div 
                        className="modal-error-message" 
                        role="alert"
                        aria-live="polite"
                    >
                        {error}
                    </div>
                )}
                <form 
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleCreateProjectSubmit(inputValue);
                    }}
                    aria-label="Create new project form"
                >
                    <input
                        id="project-name"
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder="Enter name"
                        className="create-project-input"
                        aria-invalid={error ? "true" : "false"}
                        aria-describedby={error ? "error-message" : undefined}
                        maxLength="20"
                    />
                    <div className="create-project-modal-buttons">
                        <button 
                            type="button" 
                            onClick={() => dispatch(closeModal())} 
                            className="create-project-cancel-button"
                            aria-label="Cancel project creation"
                        >
                            Cancel
                        </button>
                        <button 
                            type="submit" 
                            className="create-project-submit-button"
                            aria-label="Create new project"
                        >
                            Submit
                        </button>
                    </div>
                </form>

                <div className="create-project-alternative" role="region" aria-label="Alternative options">
                    <p>Or, click a button below to explore what LabKick can do</p>
                </div>

                <div className="create-project-buttons-container">
                    <button 
                        className="create-project-button" 
                        onClick={() => handleSampleClick(1)}
                        aria-label="Start tutorial"
                    >
                        <img src="/images/key.svg" alt="" role="presentation" />
                        Tutorial
                    </button>
                    <button 
                        className="create-project-button" 
                        onClick={() => handleSampleClick(2)}
                        aria-label="View example project"
                    >
                        <img src="/images/dna-archive-fix.svg" alt="" role="presentation" />
                        Example
                    </button>
                    <button 
                        className="create-project-button" 
                        onClick={handleAppNotesClick}
                        aria-label="Open app notes in new tab"
                    >
                        <img src="/images/new_project.svg" alt="" role="presentation" />
                        App Notes
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CreateProjectModal;