import React, { useEffect, useState } from "react";
import "../style/GroupManagementModal.css";
import { useDispatch, useSelector } from "react-redux";
import { updateModal } from "../store/project.js";
import { fetchGroups } from "../store/user.js";
import CreateGroupModal from "./CreateGroupModal.js";
import ManageGroup from "./ManageGroup.js";

const GroupManagementModal = () => {
    const dispatch = useDispatch();
    const groupsObj = useSelector((state) => state.user.groups);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);

    // Transform groups object into array
    const groups = Object.values(groupsObj || {});

    useEffect(() => {
        if (selectedGroup) {
            // Find the updated version of the selected group
            const updatedGroup = groupsObj[selectedGroup.group_id];
            if (updatedGroup) {
                setSelectedGroup(updatedGroup);
            }
        }
    }, [groupsObj, selectedGroup]);

    useEffect(() => {
        dispatch(fetchGroups());
    }, [dispatch]);

    const handleClose = () => {
        dispatch(updateModal({ name: "", data: null }));
    };

    const handleBack = () => {
        dispatch(updateModal({ name: "payments", data: null }));
    };

    return (
        <div 
            className="group-modal-overlay" 
            onClick={handleClose}
            role="dialog"
            aria-modal="true"
            aria-labelledby="group-modal-title"
        >
            <div 
                className="group-modal-content" 
                onClick={(e) => e.stopPropagation()}
                role="document"
            >
                <div className="group-modal-header">
                    <h2 className="group-modal-title" id="group-modal-title">Group Management</h2>
                    <button 
                        className="group-management-back-button" 
                        onClick={handleBack}
                        aria-label="Back to Payments"
                    >
                        ← Back to Payments
                    </button>
                </div>
                <hr className="group-modal-divider" />
                
                <div className="group-description" role="region" aria-label="Group Description">
                    <p>Groups allow you to share credits between team members. Create a group, add members, and manage shared credits for your organization or team.</p>
                </div>
                <hr className="group-modal-divider" />
                
                <div className="group-list" role="list">
                    {groups.map(group => (
                        <div key={group.group_id} className="group-item" role="listitem">
                            <div className="group-info">
                                <h3 id={`group-${group.group_id}-name`}>{group.name}</h3>
                                <p aria-label={`${group.member_count} members and ${group.num_credits} credits`}>
                                    {group.member_count} members • {group.num_credits} credits
                                </p>
                            </div>
                            <button 
                                className="group-manage-button"
                                onClick={() => setSelectedGroup(group)}
                                aria-label={`Manage ${group.name}`}
                            >
                                Manage
                            </button>
                        </div>
                    ))}
                </div>

                <button 
                    className="group-add-button"
                    onClick={() => setShowCreateModal(true)}
                    aria-label="Create New Group"
                >
                    Create New Group
                </button>
            </div>
            {showCreateModal && (
                <CreateGroupModal onClose={() => setShowCreateModal(false)} />
            )}
            {selectedGroup && (
                <ManageGroup 
                    group={selectedGroup} 
                    onClose={() => setSelectedGroup(null)} 
                />
            )}
        </div>
    );
};

export default GroupManagementModal;
