export const processProjectFiles = (project) => {
    const fileStructure = [
        {
            type: "folder",
            name: "Parts",
            id: "dna-folder",
            uuid: "dna-folder-uuid",
            children: []
        },
        // {
        //     type: "folder",
        //     name: "Resources",
        //     id: "resources-folder",
        //     uuid: "resources-folder-uuid",
        //     children: []
        // },
        {
            type: "folder",
            name: "Protocols",
            id: "outputs-folder",
            uuid: "outputs-folder-uuid",
            children: []
        },
        {
            type: "folder",
            name: "Constructs",
            id: "constructs-folder",
            uuid: "constructs-folder-uuid",
            children: []
        }
    ];
    let dnaFileCount = 0;
    // let resourcesFileCount = 0;
    let outputsFileCount = 0;
    let constructsFileCount = 0;

    // Ensure project.files is an object before processing
    const files = project.files || {};

    console.log("Files", files);

    Object.entries(files).forEach(([fileId, fileData]) => {
        const fileItem = {
            type: "file",
            name: fileData.file_name,
            uuid: fileId,
            size: fileData.size || 0,
            category: fileData.category,
            s3_address: fileData.s3_address,
            file_type: fileData.file_type
        };

        if (fileData.file_type === "DNA") {
            fileStructure[0].children.push(fileItem);
            dnaFileCount++;
        } else if (fileData.file_type === "Output") {
            fileStructure[1].children.push(fileItem);
            outputsFileCount++;
        } else if (fileData.file_type === "TargetConstruct") {
            fileStructure[2].children.push(fileItem);
            constructsFileCount++;
        } else {
            fileStructure.push(fileItem);
        }
    });

    fileStructure[0].dnaFileCount = dnaFileCount;
    fileStructure[0].partsFileCount = dnaFileCount;
    // fileStructure[1].resourcesFileCount = resourcesFileCount;
    fileStructure[1].outputsFileCount = outputsFileCount;
    fileStructure[1].protocolsFileCount = outputsFileCount;
    fileStructure[2].constructsFileCount = constructsFileCount;
    fileStructure[2].targetConstructFileCount = constructsFileCount;
    return fileStructure;
};