import React, { useRef, useEffect, useState, useCallback } from "react";
import "../style/UserPreferencesModal.css";
import { saveUserPreferences, getUserPreferences } from "../api.js";
import { useSelector, useDispatch } from "react-redux";
import { updateModal } from "../store/project.js";
import {
    pcr_protocol_list,
    digest_protocol_list,
    synthesis_to_order_protocol_list,
    synthesis_to_order_gene_protocol_list,
    synthesis_to_order_primer_protocol_list,
    gibson_protocol_list,
    golden_gate_protocol_list,
    traditional_protocol_list
} from "../store/protocol_list.js";
import Select from 'react-select';
import { debounce } from 'lodash';
import { Tooltip } from 'react-tooltip';
import { ladders } from "../store/ladders.js";

const defaultPreferences = {
    pcr_protocol: 'q5_high_fidelity_neb',
    pcr_reaction_size: '20',
    primer_concentration: '10',
    digest_vendor: 'NEB',
    digest_reaction_size: '10',
    synthesis_to_order_protocol: 'synthesis_to_order_idt_gene',
    synthesis_to_order_primer_protocol: 'synthesis_to_order_idt_primer',
    gibson_protocol: 'nebuilder_hifi_neb',
    gibson_reaction_size: '50',
    gibson_restriction_site_regeneration: 'None',
    gibson_default_homology_length: '40',
    golden_gate_protocol: 'nebridge_bsmbiv2_neb',
    golden_gate_reaction_size: '20',
    traditional_protocol: 't4_dna_ligation_neb',
    traditional_reaction_size: '20',
    digest_ladder: 'NEB_1 kb Plus DNA Ladder',
};

function UserPreferencesModal() {
    const modalRef = useRef(null);
    const [editMode, setEditMode] = useState({});
    const [userPrefs, setUserPrefs] = useState(null);
    const [preferencesLoaded, setPreferencesLoaded] = useState(false);
    const { modal } = useSelector((state) => state.project);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.data);
    const token = useSelector(state => state.user.access_token);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [selectedProtocol, setSelectedProtocol] = useState('q5_high_fidelity_neb');
    const [selectedDigestVendor, setSelectedDigestVendor] = useState('NEB');
    const [selectedSynthesisProtocol, setSelectedSynthesisProtocol] = useState('synthesis_to_order_idt_gene');
    const [selectedSynthesisPrimerProtocol, setSelectedSynthesisPrimerProtocol] = useState('synthesis_to_order_idt_primer');
    const [selectedGibsonProtocol, setSelectedGibsonProtocol] = useState('nebuilder_hifi_neb');
    const [selectedGoldenGateProtocol, setSelectedGoldenGateProtocol] = useState('nebridge_bsmbiv2_neb');
    const [selectedTraditionalProtocol, setSelectedTraditionalProtocol] = useState('t4_dna_ligation_neb');
    const [selectedGibsonRegeneration, setSelectedGibsonRegeneration] = useState('None');
    const [gibsonHomologyLength, setGibsonHomologyLength] = useState('40');
    const [expandedSections, setExpandedSections] = useState({
        assembly: true,
        protocols: true,
    });
    const [expandedProtocols, setExpandedProtocols] = useState({
        pcr: false,
        digest: false,
        assembly: false
    });
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [pendingChanges, setPendingChanges] = useState({});
    const lastSaveTimeRef = useRef(Date.now());
    const [showUnsavedWarning, setShowUnsavedWarning] = useState(false);
    const [selectedLadder, setSelectedLadder] = useState('NEB_1 kb Plus DNA Ladder');

    useEffect(() => {
        let timer;
        if (successMessage || errorMessage) {
            timer = setTimeout(() => {
                setSuccessMessage("");
                setErrorMessage("");
            }, 5000); // Increased to 5 seconds
        }
        return () => clearTimeout(timer);
    }, [successMessage, errorMessage]);

    useEffect(() => {
        const fetchUserPreferences = async () => {
            if (!preferencesLoaded && user) {
                try {
                    const preferences = await getUserPreferences(user.user_id, token);
                    setUserPrefs({
                        ...preferences,
                        cloning_preferences: {
                            ...preferences.cloning_preferences,
                            pcr_protocol: preferences.cloning_preferences?.pcr_protocol || 'q5_high_fidelity_neb',
                            pcr_reaction_size: preferences.cloning_preferences?.pcr_reaction_size || '20',
                            primer_concentration: preferences.cloning_preferences?.primer_concentration || '10',
                            digest_vendor: preferences.cloning_preferences?.digest_vendor || 'NEB',
                            digest_reaction_size: preferences.cloning_preferences?.digest_reaction_size || '10',
                            synthesis_to_order_protocol: preferences.cloning_preferences?.synthesis_to_order_protocol || 'synthesis_to_order_idt_gene',
                            synthesis_to_order_primer_protocol: preferences.cloning_preferences?.synthesis_to_order_primer_protocol || 'synthesis_to_order_idt_primer',
                            gibson_protocol: preferences.cloning_preferences?.gibson_protocol || 'nebuilder_hifi_neb',
                            gibson_reaction_size: preferences.cloning_preferences?.gibson_reaction_size || '50',
                            gibson_restriction_site_regeneration: preferences.cloning_preferences?.gibson_restriction_site_regeneration || 'None',
                            gibson_default_homology_length: preferences.cloning_preferences?.gibson_default_homology_length || '40',
                            golden_gate_protocol: preferences.cloning_preferences?.golden_gate_protocol || 'nebridge_bsmbiv2_neb',
                            golden_gate_reaction_size: preferences.cloning_preferences?.golden_gate_reaction_size || '20',
                            traditional_protocol: preferences.cloning_preferences?.traditional_protocol || 't4_dna_ligation_neb',
                            traditional_reaction_size: preferences.cloning_preferences?.traditional_reaction_size || '20',
                            digest_ladder: preferences.cloning_preferences?.digest_ladder || 'NEB_1 kb Plus DNA Ladder',
                        }
                    });

                    setSelectedProtocol(preferences.cloning_preferences?.pcr_protocol || 'q5_high_fidelity_neb');
                    setSelectedDigestVendor(preferences.cloning_preferences?.digest_vendor || 'NEB');
                    setSelectedSynthesisProtocol(preferences.cloning_preferences?.synthesis_to_order_protocol || 'synthesis_to_order_idt_gene');
                    setSelectedSynthesisPrimerProtocol(preferences.cloning_preferences?.synthesis_to_order_primer_protocol || 'synthesis_to_order_idt_primer');
                    setSelectedGibsonProtocol(preferences.cloning_preferences?.gibson_protocol || 'nebuilder_hifi_neb');
                    setSelectedGoldenGateProtocol(preferences.cloning_preferences?.golden_gate_protocol || 'nebridge_bsmbiv2_neb');
                    setSelectedTraditionalProtocol(preferences.cloning_preferences?.traditional_protocol || 't4_dna_ligation_neb');
                    setSelectedGibsonRegeneration(preferences.cloning_preferences?.gibson_restriction_site_regeneration || 'None');
                    setGibsonHomologyLength(preferences.cloning_preferences?.gibson_default_homology_length || '40');
                    setSelectedLadder(preferences.cloning_preferences?.digest_ladder || 'NEB_1 kb Plus DNA Ladder');
                    setPreferencesLoaded(true);
                } catch (err) {
                    console.error("Error fetching user preferences:", err);
                }
            }
        };

        fetchUserPreferences();
    }, [preferencesLoaded, user, token]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target)
            ) {
                if (hasUnsavedChanges) {
                    setShowUnsavedWarning(true);
                } else {
                    dispatch(updateModal({ name: '', data: null }));
                    setSuccessMessage("");
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dispatch, hasUnsavedChanges]);

    useEffect(() => {
        const handleEscKey = (event) => {
            if (event.key === 'Escape') {
                if (hasUnsavedChanges) {
                    setShowUnsavedWarning(true);
                } else {
                    dispatch(updateModal({ name: '', data: null }));
                }
            }
        };

        document.addEventListener('keydown', handleEscKey);
        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [dispatch, hasUnsavedChanges]);

    const toggleEdit = (field) => {
        setEditMode((prev) => ({ ...prev, [field]: !prev[field] }));
    };

    const handleInputChange = (field, value) => {
        setUserPrefs(prev => ({
            ...prev,
            cloning_preferences: {
                ...prev.cloning_preferences,
                [field]: value
            }
        }));
        setPendingChanges(prev => ({
            ...prev,
            [field]: value
        }));
        setHasUnsavedChanges(true);
        lastSaveTimeRef.current = Date.now(); // Reset the timer when changes are made
    };

    const handleProtocolChange = (e, type) => {
        const value = e.target.value;

        const updates = {};
        switch (type) {
            case 'pcr':
                setSelectedProtocol(value);
                updates.pcr_protocol = value;
                break;
            case 'digest_vendor':
                setSelectedDigestVendor(value);
                updates.digest_vendor = value;
                break;
            case 'synthesis_to_order':
                setSelectedSynthesisProtocol(value);
                updates.synthesis_to_order_protocol = value;
                break;
            case 'synthesis_to_order_primer':
                setSelectedSynthesisPrimerProtocol(value);
                updates.synthesis_to_order_primer_protocol = value;
                break;
            case 'gibson':
                setSelectedGibsonProtocol(value);
                updates.gibson_protocol = value;
                break;
            case 'golden_gate':
                setSelectedGoldenGateProtocol(value);
                updates.golden_gate_protocol = value;
                break;
            case 'traditional':
                setSelectedTraditionalProtocol(value);
                updates.traditional_protocol = value;
                break;
            case 'gibson_regeneration':
                setSelectedGibsonRegeneration(value);
                updates.gibson_restriction_site_regeneration = value;
                break;
            case 'gibson_homology':
                setGibsonHomologyLength(value);
                updates.gibson_default_homology_length = value;
                break;
            case 'digest_ladder':
                setSelectedLadder(value);
                updates.digest_ladder = value;
                break;
        }

        setUserPrefs(prev => ({
            ...prev,
            cloning_preferences: {
                ...prev.cloning_preferences,
                ...updates
            }
        }));
        setPendingChanges(prev => ({
            ...prev,
            ...updates
        }));
        setHasUnsavedChanges(true);
        lastSaveTimeRef.current = Date.now(); // Reset the timer when changes are made
    };

    const renderProtocolDropdown = (type, value, list) => {
        const options = Object.entries(list).map(([key, protocol]) => ({
            value: key,
            label: protocol.Name,
            vendor: protocol.Vendor
        }));

        return (
            <Select
                value={options.find(option => option.value === value)}
                onChange={(selected) => handleProtocolChange({ target: { value: selected.value } }, type)}
                options={options}
                className="up-select"
                isSearchable={true}
                placeholder="Type to search..."
                aria-label={`${type.charAt(0).toUpperCase() + type.slice(1)} Protocol`}
                aria-invalid={errorMessage ? "true" : "false"}
            />
        );
    };

    const renderDigestVendorDropdown = () => {
        const options = [
            { value: 'NEB', label: 'NEB' },
            { value: 'Thermo', label: 'Thermo' }
        ];

        return (
            <Select
                value={options.find(option => option.value === selectedDigestVendor)}
                onChange={(selected) => handleProtocolChange({ target: { value: selected.value } }, 'digest_vendor')}
                options={options}
                className="up-select"
                isSearchable={true}
                aria-label="Digest Vendor"
                aria-invalid={errorMessage ? "true" : "false"}
            />
        );
    };

    const renderLadderDropdown = () => {
        const options = Object.entries(ladders).map(([key, ladder]) => ({
            value: key,
            label: `${ladder.vendor} - ${ladder.name}`,
            vendor: ladder.vendor
        }));

        return (
            <Select
                value={options.find(option => option.value === selectedLadder)}
                onChange={(selected) => handleProtocolChange({ target: { value: selected.value } }, 'digest_ladder')}
                options={options}
                className="up-select"
                isSearchable={true}
                placeholder="Select a ladder..."
                aria-label="DNA Ladder"
                aria-invalid={errorMessage ? "true" : "false"}
            />
        );
    };

    const dismissMessage = () => {
        setSuccessMessage("");
        setErrorMessage("");
    };

    const toggleSection = (section) => {
        setExpandedSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    const toggleProtocolSection = (section) => {
        setExpandedProtocols(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    const handleReset = async () => {
        // First set all the states to defaults
        setUserPrefs(prev => ({
            ...prev,
            cloning_preferences: { ...defaultPreferences }
        }));
        setSelectedProtocol(defaultPreferences.pcr_protocol);
        setSelectedDigestVendor(defaultPreferences.digest_vendor);
        setSelectedSynthesisProtocol(defaultPreferences.synthesis_to_order_protocol);
        setSelectedSynthesisPrimerProtocol(defaultPreferences.synthesis_to_order_primer_protocol);
        setSelectedGibsonProtocol(defaultPreferences.gibson_protocol);
        setSelectedGoldenGateProtocol(defaultPreferences.golden_gate_protocol);
        setSelectedTraditionalProtocol(defaultPreferences.traditional_protocol);
        setSelectedGibsonRegeneration(defaultPreferences.gibson_restriction_site_regeneration);
        setGibsonHomologyLength(defaultPreferences.gibson_default_homology_length);
        setSelectedLadder(defaultPreferences.digest_ladder);

        // Then immediately save the default preferences
        try {
            const updatedPrefs = {
                ...userPrefs,
                user_id: String(user.user_id),
                cloning_preferences: { ...defaultPreferences }
            };

            const savedPrefs = await saveUserPreferences(updatedPrefs, token);
            setUserPrefs(savedPrefs);
            setEditMode({});
            setErrorMessage("");
            setHasUnsavedChanges(false);
            setSuccessMessage("Settings reset to defaults");
        } catch (error) {
            console.error("Failed to save default preferences:", error);
            setErrorMessage("Failed to reset to defaults. Please try again.");
        }
    };

    const renderRestrictionStrategySelect = () => {
        const options = [
            {
                value: 'None',
                label: 'None',
                tooltip: 'Ignores overhangs during assembly.'
            },
            {
                value: 'Strict',
                label: 'Strict',
                tooltip: 'Adds back bases to rebuild the original restriction site.'
            },
            {
                value: 'Eliminate',
                label: 'Eliminate',
                tooltip: 'Removes enzyme site. Only possible with assembly methods that can remove 3\' mismatches, such as NEB HiFi.'
            }
        ];

        const selectedOption = options.find(option => option.value === selectedGibsonRegeneration);

        return (
            <div className="up-select-container">
                <div
                    data-tooltip-id="restriction-strategy-tooltip"
                    data-tooltip-content={selectedOption?.tooltip}
                    className="up-select-wrapper"
                >
                    <Select
                        value={options.find(option => option.value === selectedGibsonRegeneration)}
                        onChange={(selected) => handleProtocolChange({ target: { value: selected.value } }, 'gibson_regeneration')}
                        options={options}
                        className="up-select"
                        isSearchable={true}
                        placeholder="Type to search..."
                        components={{
                            Option: ({ children, ...props }) => (
                                <div
                                    {...props.innerProps}
                                    className="up-select-option"
                                    data-tooltip-id="restriction-strategy-tooltip"
                                    data-tooltip-content={props.data.tooltip}
                                    style={{
                                        padding: '8px 12px',
                                        backgroundColor: props.isFocused ? '#f5f5f5' : 'white',
                                        color: '#333',
                                        cursor: 'pointer',
                                        textAlign: 'center',
                                        position: 'relative'
                                    }}
                                >
                                    {children}
                                </div>
                            )
                        }}
                        aria-label="Restriction Site Regeneration Strategy"
                        aria-invalid={errorMessage ? "true" : "false"}
                    />
                </div>
                <Tooltip
                    id="restriction-strategy-tooltip"
                    place="top"
                    className="up-tooltip"
                    delayShow={200}
                    delayHide={100}
                    float={true}
                    offset={20}
                />
            </div>
        );
    };

    const handleSaveAndClose = async () => {
        try {
            setIsSaving(true);
            await saveUserPreferences(userPrefs, token);
            setSuccessMessage("Saved");
            setHasUnsavedChanges(false);
            setPendingChanges({});
            dispatch(updateModal({ name: '', data: null }));
        } catch (error) {
            setErrorMessage("Failed to save changes");
        } finally {
            setIsSaving(false);
            setShowUnsavedWarning(false);
        }
    };

    const handleDiscardAndClose = () => {
        setShowUnsavedWarning(false);
        setHasUnsavedChanges(false);
        setPendingChanges({});
        dispatch(updateModal({ name: '', data: null }));
    };

    const handleCancelClose = () => {
        setShowUnsavedWarning(false);
    };

    const handleClose = () => {
        if (hasUnsavedChanges) {
            setShowUnsavedWarning(true);
        } else {
            dispatch(updateModal({ name: '', data: null }));
        }
    };

    const handleSave = async () => {
        try {
            setIsSaving(true);
            await saveUserPreferences(userPrefs, token);
            setSuccessMessage("Changes saved successfully");
            setHasUnsavedChanges(false);
            setPendingChanges({});
        } catch (error) {
            setErrorMessage("Failed to save changes");
        } finally {
            setIsSaving(false);
        }
    };

    // Add keyboard handler for modal
    const handleKeyDown = (event) => {
        if (event.key === 'Tab') {
            // Keep focus trapped in modal when tabbing
            const focusableElements = modalRef.current.querySelectorAll(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
            const firstElement = focusableElements[0];
            const lastElement = focusableElements[focusableElements.length - 1];

            if (event.shiftKey && document.activeElement === firstElement) {
                event.preventDefault();
                lastElement.focus();
            } else if (!event.shiftKey && document.activeElement === lastElement) {
                event.preventDefault();
                firstElement.focus();
            }
        }
    };

    // Add to existing useEffect for modal
    useEffect(() => {
        if (modal.name === 'userPreferences') {
            // Set focus to first focusable element when modal opens
            const firstFocusable = modalRef.current.querySelector(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
            if (firstFocusable) {
                firstFocusable.focus();
            }
        }
    }, [modal]);

    if (!user || !userPrefs) {
        return (
            <div className="up-modal-overlay">
                <div className="up-modal-content" ref={modalRef}>
                    <h2>Loading settings...</h2>
                </div>
            </div>
        );
    }

    return (
        <div
            className="up-modal-overlay"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-title"
            onKeyDown={handleKeyDown}
        >
            <div
                className="up-modal-content"
                ref={modalRef}
                tabIndex="-1"
            >
                <div className="up-modal-header">
                    <h2 id="modal-title">
                        Settings
                        {isSaving && <span className="up-saving-indicator" role="status">Saving...</span>}
                        {hasUnsavedChanges && <span className="up-unsaved-indicator" aria-label="Unsaved changes">*</span>}
                    </h2>
                    <button
                        onClick={handleClose}
                        className="up-close-button"
                        aria-label="Close settings"
                    >×</button>
                </div>
                <p className="up-description">
                    Configure your global settings for DNA assembly and synthesis. These preferences will be applied to all new projects. Currently, these settings cannot be customized per project.
                </p>
                {successMessage && (
                    <div className="up-success-message">
                        <p>{successMessage}</p>
                    </div>
                )}
                <div className="up-main-content">
                    <div className="up-fields-container">
                        <div className={`up-section ${expandedSections.assembly ? 'expanded' : ''}`}>
                            <div className="up-section-header" onClick={() => toggleSection('assembly')}>
                                <h3 className="up-section-title">
                                    Assembly Settings
                                </h3>
                                <span className="up-section-icon">▼</span>
                            </div>
                            <div className="up-section-description">
                                Configure DNA fragment assembly settings and overlap parameters.
                            </div>
                            <div className="up-category">
                                <div className="up-category-content">
                                    <div className="up-field">
                                        <div className="up-field-row">
                                            <label>
                                                Restriction Site<br />
                                                Regeneration Strategy:
                                            </label>
                                            {renderRestrictionStrategySelect()}
                                        </div>
                                        <div className="up-field-description">
                                            Controls how restriction sites are handled during homology-based assembly.
                                        </div>
                                    </div>
                                    <div className="up-field">
                                        <div className="up-field-row">
                                            <label>Overlap Homology Length:</label>
                                            <div className="up-input-wrapper">
                                                <div className="up-input-group">
                                                    <input
                                                        type="number"
                                                        value={gibsonHomologyLength}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            setGibsonHomologyLength(value);
                                                        }}
                                                        onBlur={(e) => {
                                                            const value = parseInt(e.target.value);
                                                            let finalValue = value;

                                                            if (isNaN(value) || value === 0 || value === '') {
                                                                finalValue = '40';
                                                            } else if (value < 20) {
                                                                finalValue = '20';
                                                                setErrorMessage("Minimum overlap length is 20 bp");
                                                            } else if (value > 50) {
                                                                finalValue = '50';
                                                                setErrorMessage("Maximum overlap length is 50 bp");
                                                            }

                                                            setGibsonHomologyLength(finalValue);
                                                            handleProtocolChange({ target: { value: finalValue } }, 'gibson_homology');
                                                        }}
                                                        className="up-input"
                                                        min="20"
                                                        max="50"
                                                        step="1"
                                                        aria-label="Overlap Homology Length"
                                                        aria-invalid={errorMessage ? "true" : "false"}
                                                        aria-describedby={errorMessage ? "homology-error" : "homology-description"}
                                                    />
                                                    <span className="up-input-suffix" aria-hidden="true">bp</span>
                                                </div>
                                                {errorMessage && <div id="homology-error" className="up-field-error" role="alert">{errorMessage}</div>}
                                            </div>
                                        </div>
                                        <div className="up-field-description">
                                            Total homology length for Gibson assembly. Must be between 20 and 50 base pairs.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`up-section ${expandedSections.protocols ? 'expanded' : ''}`}>
                            <div className="up-section-header" onClick={() => toggleSection('protocols')}>
                                <h3 className="up-section-title">
                                    Protocol Settings
                                </h3>
                                <span className="up-section-icon">▼</span>
                            </div>
                            <div className="up-section-description">
                                Set default protocols and reaction conditions for PCR, digests, and assembly methods.
                            </div>
                            <div className="up-category">
                                <div className="up-category-content">
                                    {/* PCR Protocol Group */}
                                    <div className={`up-protocol-group ${expandedProtocols.pcr ? 'expanded' : ''}`}>
                                        <div className="up-protocol-header" onClick={() => toggleProtocolSection('pcr')}>
                                            <h4>PCR</h4>
                                            <span className="up-section-icon">▼</span>
                                        </div>
                                        <div className="up-protocol-content">
                                            <div className="up-field">
                                                <div className="up-field-row">
                                                    <label>Protocol:</label>
                                                    {renderProtocolDropdown('pcr', selectedProtocol, pcr_protocol_list)}
                                                </div>
                                                <div className="up-field-description">
                                                    Default polymerase for PCR reactions. A high-fidelity polymerase like Q5 or Phusion is recommended.
                                                </div>
                                            </div>
                                            <div className="up-field">
                                                <div className="up-field-row">
                                                    <label>Reaction Size:</label>
                                                    <div className="up-input-group">
                                                        <input
                                                            type="number"
                                                            value={userPrefs?.cloning_preferences?.pcr_reaction_size || ""}
                                                            onChange={(e) => handleInputChange("pcr_reaction_size", e.target.value)}
                                                            className="up-input"
                                                        />
                                                        <span className="up-input-suffix">µL</span>
                                                    </div>
                                                </div>
                                                <div className="up-field-description">
                                                    Standard reaction size for PCR reactions. Use 20µL for screening and 50µL for preparative reactions.
                                                </div>
                                            </div>
                                            <div className="up-field">
                                                <div className="up-field-row">
                                                    <label>Primer Concentration:</label>
                                                    <div className="up-input-group">
                                                        <input
                                                            type="number"
                                                            value={userPrefs?.cloning_preferences?.primer_concentration || ""}
                                                            onChange={(e) => handleInputChange("primer_concentration", e.target.value)}
                                                            className="up-input"
                                                        />
                                                        <span className="up-input-suffix">µM</span>
                                                    </div>
                                                </div>
                                                <div className="up-field-description">
                                                    Standard working concentration for primers in PCR reactions.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Enzyme Digest Group */}
                                    <div className={`up-protocol-group ${expandedProtocols.digest ? 'expanded' : ''}`}>
                                        <div className="up-protocol-header" onClick={() => toggleProtocolSection('digest')}>
                                            <h4>Enzyme Digest</h4>
                                            <span className="up-section-icon">▼</span>
                                        </div>
                                        <div className="up-protocol-content">
                                            <div className="up-field">
                                                <div className="up-field-row">
                                                    <label>Digest Vendor:</label>
                                                    {renderDigestVendorDropdown()}
                                                </div>
                                                <div className="up-field-description">
                                                    Default vendor for restriction enzymes. Affects buffer compatibility calculations.
                                                </div>
                                            </div>
                                            <div className="up-field">
                                                <div className="up-field-row">
                                                    <label>DNA Ladder:</label>
                                                    {renderLadderDropdown()}
                                                </div>
                                                <div className="up-field-description">
                                                    Default DNA ladder for gel electrophoresis analysis of restriction digests.
                                                </div>
                                            </div>
                                            <div className="up-field">
                                                <div className="up-field-row">
                                                    <label>Reaction Size:</label>
                                                    <div className="up-input-group">
                                                        <input
                                                            type="number"
                                                            value={userPrefs?.cloning_preferences?.digest_reaction_size || ""}
                                                            onChange={(e) => handleInputChange("digest_reaction_size", e.target.value)}
                                                            className="up-input"
                                                        />
                                                        <span className="up-input-suffix">µL</span>
                                                    </div>
                                                </div>
                                                <div className="up-field-description">
                                                    Standard reaction size for restriction digests. Typically 10-50µL depending on application.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Assembly Group */}
                                    <div className={`up-protocol-group ${expandedProtocols.assembly ? 'expanded' : ''}`}>
                                        <div className="up-protocol-header" onClick={() => toggleProtocolSection('assembly')}>
                                            <h4>Assembly</h4>
                                            <span className="up-section-icon">▼</span>
                                        </div>
                                        <div className="up-protocol-content">
                                            <div className="up-protocol-section">
                                                <div className="up-protocol-section-header">Homology-based Assembly</div>
                                                <div className="up-protocol-section-content">
                                                    <div className="up-field">
                                                        <div className="up-field-row">
                                                            <label>Protocol:</label>
                                                            {renderProtocolDropdown('gibson', selectedGibsonProtocol, gibson_protocol_list)}
                                                        </div>
                                                        <div className="up-field-description">
                                                            Default protocol for homology-based assembly reactions. NEBuilder HiFi is recommended for most applications.
                                                        </div>
                                                    </div>
                                                    <div className="up-field">
                                                        <div className="up-field-row">
                                                            <label>Reaction Size:</label>
                                                            <div className="up-input-group">
                                                                <input
                                                                    type="number"
                                                                    value={userPrefs?.cloning_preferences?.gibson_reaction_size || ""}
                                                                    onChange={(e) => handleInputChange("gibson_reaction_size", e.target.value)}
                                                                    className="up-input"
                                                                />
                                                                <span className="up-input-suffix">µL</span>
                                                            </div>
                                                        </div>
                                                        <div className="up-field-description">
                                                            Standard reaction size for homology-based assembly. Typically 20µL for standard reactions.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="up-protocol-section">
                                                <div className="up-protocol-section-header">Type IIS Mediated Assembly</div>
                                                <div className="up-protocol-section-content">
                                                    <div className="up-field">
                                                        <div className="up-field-row">
                                                            <label>Protocol:</label>
                                                            {renderProtocolDropdown('golden_gate', selectedGoldenGateProtocol, golden_gate_protocol_list)}
                                                        </div>
                                                        <div className="up-field-description">
                                                            Default protocol for Type IIS mediated assembly reactions.
                                                        </div>
                                                    </div>
                                                    <div className="up-field">
                                                        <div className="up-field-row">
                                                            <label>Reaction Size:</label>
                                                            <div className="up-input-group">
                                                                <input
                                                                    type="number"
                                                                    value={userPrefs?.cloning_preferences?.golden_gate_reaction_size || ""}
                                                                    onChange={(e) => handleInputChange("golden_gate_reaction_size", e.target.value)}
                                                                    className="up-input"
                                                                />
                                                                <span className="up-input-suffix">µL</span>
                                                            </div>
                                                        </div>
                                                        <div className="up-field-description">
                                                            Standard reaction size for Type IIS mediated assembly. Typically 20µL for standard reactions.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="up-protocol-section">
                                                <div className="up-protocol-section-header">Restriction Enzyme Cloning</div>
                                                <div className="up-protocol-section-content">
                                                    <div className="up-field">
                                                        <div className="up-field-row">
                                                            <label>Protocol:</label>
                                                            {renderProtocolDropdown('traditional', selectedTraditionalProtocol, traditional_protocol_list)}
                                                        </div>
                                                        <div className="up-field-description">
                                                            Default protocol for restriction enzyme-based cloning.
                                                        </div>
                                                    </div>
                                                    <div className="up-field">
                                                        <div className="up-field-row">
                                                            <label>Reaction Size:</label>
                                                            <div className="up-input-group">
                                                                <input
                                                                    type="number"
                                                                    value={userPrefs?.cloning_preferences?.traditional_reaction_size || ""}
                                                                    onChange={(e) => handleInputChange("traditional_reaction_size", e.target.value)}
                                                                    className="up-input"
                                                                />
                                                                <span className="up-input-suffix">µL</span>
                                                            </div>
                                                        </div>
                                                        <div className="up-field-description">
                                                            Standard reaction size for restriction enzyme cloning reactions. Typically 20µL for standard reactions.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="up-modal-footer">
                    <button onClick={handleReset} className="up-reset-button">
                        Reset to Defaults
                    </button>
                    <div className="up-footer-right">
                        <button
                            onClick={handleSave}
                            className={`up-save-button ${hasUnsavedChanges ? 'has-changes' : ''}`}
                            disabled={!hasUnsavedChanges || isSaving}
                        >
                            {isSaving ? 'Saving...' : 'Save Changes'}
                        </button>
                    </div>
                </div>
                {showUnsavedWarning && (
                    <div
                        className="up-warning-modal"
                        role="alertdialog"
                        aria-labelledby="warning-title"
                        aria-describedby="warning-message"
                    >
                        <div className="up-warning-content">
                            <h3 id="warning-title">Unsaved Changes</h3>
                            <p id="warning-message">You have unsaved changes. Would you like to save them before closing?</p>
                            <div className="up-warning-buttons">
                                <button
                                    onClick={handleSaveAndClose}
                                    className="up-save-button"
                                    aria-label="Save changes and close"
                                >
                                    Save & Close
                                </button>
                                <button
                                    onClick={handleDiscardAndClose}
                                    className="up-discard-button"
                                    aria-label="Discard changes and close"
                                >
                                    Discard & Close
                                </button>
                                <button
                                    onClick={handleCancelClose}
                                    className="up-cancel-button"
                                    aria-label="Cancel closing"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default UserPreferencesModal;
