import React, { useState, useEffect } from "react";
import "../style/PaymentModal.css";
import { createCheckoutSession, resendVerificationEmail } from "../api.js";
import { useSelector, useDispatch } from "react-redux";
import { updateModal } from "../store/project.js";
import { useAuth0 } from '@auth0/auth0-react';

const PaymentModal = () => {
    const dispatch = useDispatch();
    const token = useSelector(state => state.user.access_token);
    const userId = useSelector(state => state.user.user_id);
    const [message, setMessage] = useState("");
    const [credits, setCredits] = useState("100");
    const currentCredits = useSelector(state => state.user.usageCredits) || 0;
    const [isEmailVerificationPopupOpen, setIsEmailVerificationPopupOpen] = useState(false);
    const { getAccessTokenSilently, user: userData } = useAuth0();

    useEffect(() => {
        checkUrlParams();
    }, []);

    const checkUrlParams = () => {
        const searchParams = new URLSearchParams(window.location.search);

        if (searchParams.get("success") === "true") {
            dispatch(setPopupMessage("Payment succeeded! You will receive an email confirmation."));
            removeQueryParams();
            dispatch(updateModal({ name: "", data: null }));
        } else if (searchParams.get("canceled") === "true") {
            dispatch(setPopupMessage("Payment failed or was canceled. Please try again."));
            removeQueryParams();
            dispatch(updateModal({ name: "", data: null }));
        }
    };

    const removeQueryParams = () => {
        const url = new URL(window.location);
        url.searchParams.delete("success");
        url.searchParams.delete("canceled");
        window.history.replaceState({}, document.title, url.pathname);
    };

    const handleInputChange = (e) => {
        setCredits(e.target.value);
    };

    const handleCheckout = async (e) => {
        e.preventDefault();
        const creditValue = parseInt(credits);

        if (isNaN(creditValue) || !Number.isInteger(creditValue)) {
            setMessage("Please enter a whole number between 1 and 100 credits.");
            return;
        }

        if (creditValue < 1) {
            setMessage("You must purchase at least 1 credit.");
            return;
        }

        if (creditValue > 5000) {
            setMessage("For purchases over 5000 credits, please contact sales@labkick.ai for a bigger discount.");
            return;
        }

        try {
            const token = await getAccessTokenSilently();
            const response = await fetch('https://login.labkick.ai/userinfo', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const userInfo = await response.json();

            if (!userInfo.email_verified) {
                setIsEmailVerificationPopupOpen(true);
                return;
            }

            const session = await createCheckoutSession(token, userId, creditValue);
            window.location.href = session.url;
        } catch (error) {
            console.error("Error during checkout:", error);
            setMessage("An error occurred. Please try again.");
        }
    };

    const handleResendVerification = async () => {
        try {
            const token = await getAccessTokenSilently();
            await resendVerificationEmail(userData, token);
            setIsEmailVerificationPopupOpen(false);
            setMessage("Verification email sent! Please check your inbox.");
        } catch (error) {
            console.error('Error sending verification email:', error);
            setMessage("Failed to send verification email. Please try again.");
        }
    };

    const handleClose = () => {
        removeQueryParams();
        setMessage(""); // Reset message when closing the modal
        dispatch(updateModal({ name: "", data: null }));
    };

    const handleGroupManagement = () => {
        dispatch(updateModal({ name: "group_management", data: null }));
    };

    const calculatePrice = (creditAmount) => {
        const basePrice = userData?.email?.endsWith('.edu') ? 0.25 : 1.0;
        let total = creditAmount * basePrice;

        // Apply discounts to total amount
        if (creditAmount >= 500) {
            total = total * 0.8;  // 20% off total
        } else if (creditAmount >= 200) {
            total = total * 0.9;  // 10% off total
        }

        return total;
    };

    const totalPrice = Number.isInteger(Number(credits)) && Number(credits) >= 0
        ? calculatePrice(Number(credits)).toFixed(2)
        : 0;

    const getPriceBreakdown = () => {
        if (!Number.isInteger(Number(credits)) || Number(credits) <= 0) return null;

        const amount = Number(credits);
        const isAcademic = userData?.email?.endsWith('.edu');
        const basePrice = isAcademic ? 0.25 : 1.0;

        // Calculate base cost (before discounts)
        const subtotal = amount * basePrice;

        // Calculate total with new discount structure
        let total = subtotal;
        if (amount >= 500) {
            total = subtotal * 0.8;  // 20% off total
        } else if (amount >= 200) {
            total = subtotal * 0.9;  // 10% off total
        }

        return {
            subtotal: subtotal.toFixed(2),
            discount: (subtotal - total).toFixed(2),
            total: total.toFixed(2)
        };
    };

    const pricePerCredit = userData?.email?.endsWith('.edu') ? 0.25 : 1.0;

    // Add keyboard handler for modal close
    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            handleClose();
        }
    };

    return (
        <div
            className="payment-modal-overlay"
            onClick={handleClose}
            role="dialog"
            aria-modal="true"
            aria-labelledby="payment-modal-title"
            onKeyDown={handleKeyDown}
        >
            <div
                className="payment-modal-container"
                onClick={(e) => e.stopPropagation()}
                tabIndex="-1"
            >
                <div className="payment-modal-header">
                    <h2 id="payment-modal-title">Purchase Credits</h2>
                    <button
                        className="payment-modal-close"
                        onClick={handleClose}
                        aria-label="Close payment modal"
                    >×</button>
                </div>

                <div className="payment-modal-grid" role="presentation">
                    {/* Left Column */}
                    <div className="payment-modal-info" role="complementary">
                        <div className="payment-modal-card">
                            <h3 id="usage-guide">Credit Usage Guide</h3>
                            <p className="credits-explanation" aria-labelledby="usage-guide">
                                Credits are used to generate instructions for DNA design projects. The amount of credits you use is a function of the number of constructs you are designing, and whether you are doing a DNA assembly or just primer design.
                            </p>
                            <table className="payment-modal-table" aria-labelledby="usage-guide">
                                <thead>
                                    <tr>
                                        <th>Constructs</th>
                                        <th>DNA Assembly</th>
                                        <th>Primer Design</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr><td>1-8</td><td>10 credits</td><td>1 credit</td></tr>
                                    <tr><td>9-24</td><td>25 credits</td><td>2 credits</td></tr>
                                    <tr><td>25-96</td><td>50 credits</td><td>3 credits</td></tr>
                                    <tr><td>97-384</td><td>100 credits</td><td>4 credits</td></tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="payment-modal-card">
                            <h3 id="volume-discounts">Price Guide</h3>
                            <table className="payment-modal-table" aria-labelledby="volume-discounts">
                                <thead>
                                    <tr>
                                        <th>Credits</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1-199</td>
                                        <td>${userData?.email?.endsWith('.edu') ? '0.25' : '1.00'} per credit</td>
                                    </tr>
                                    <tr>
                                        <td>200-499</td>
                                        <td>10% off</td>
                                    </tr>
                                    <tr>
                                        <td>500+</td>
                                        <td>20% off</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* Right Column */}
                    <div className="payment-modal-checkout" role="form" aria-label="Credit purchase form">
                        <div className="payment-modal-credits-display" role="status">
                            <span className="payment-modal-credits-count" aria-label="Current credits">{currentCredits}</span>
                            <span className="payment-modal-credits-label">Available Credits</span>
                        </div>

                        <div className="payment-modal-purchase-form">
                            <div className="payment-modal-input-group">
                                <label id="credits-input-label" htmlFor="credits-input">Credits to Purchase</label>
                                <input
                                    id="credits-input"
                                    type="text"
                                    value={credits}
                                    onChange={handleInputChange}
                                    className="payment-modal-input"
                                    aria-invalid={message ? "true" : "false"}
                                    aria-describedby={message ? "credits-error" : undefined}
                                />
                            </div>

                            <div className="payment-modal-summary" role="region" aria-label="Price summary">
                                <div className="payment-modal-summary-row">
                                    <span>Price (${userData?.email?.endsWith('.edu') ? '0.25' : '1.00'}/credit)</span>
                                    <span>${getPriceBreakdown()?.subtotal || "0.00"}</span>
                                </div>
                                <div className="payment-modal-summary-row">
                                    <span>Discount</span>
                                    <span>-${getPriceBreakdown()?.discount || "0.00"}</span>
                                </div>
                                <div className="payment-modal-summary-row payment-modal-total">
                                    <span>Total</span>
                                    <span>${getPriceBreakdown()?.total || "0.00"}</span>
                                </div>
                            </div>

                            <div className="payment-modal-actions">
                                <button
                                    onClick={handleCheckout}
                                    className="payment-modal-button payment-modal-button-primary"
                                    aria-label="Purchase credits"
                                >
                                    Purchase Credits
                                </button>
                                <button
                                    onClick={handleGroupManagement}
                                    className="payment-modal-button payment-modal-button-secondary"
                                    aria-label="Manage groups"
                                >
                                    Manage Groups
                                </button>
                            </div>
                        </div>

                        {message && <div id="credits-error" className="payment-modal-message" role="alert">{message}</div>}
                    </div>
                </div>

                <div className="payment-modal-footer" role="contentinfo">
                    <p>Need more? <a href="mailto:sales@labkick.ai" aria-label="Contact sales via email">Contact sales</a></p>
                </div>

                {isEmailVerificationPopupOpen && (
                    <div
                        className="artifact-confirmation-popup-overlay"
                        role="alertdialog"
                        aria-labelledby="verification-title"
                        aria-describedby="verification-description"
                    >
                        <div className="artifact-confirmation-popup-dialog">
                            <h3 id="verification-title">Email Not Verified</h3>
                            <p id="verification-description">Please verify your email before purchasing credits. Check your junk mailbox if you don't see a verification email.</p>
                            <div className="artifact-confirmation-popup-buttons">
                                <button
                                    onClick={handleResendVerification}
                                    className="artifact-confirm-button"
                                    aria-label="Resend verification email"
                                >
                                    Resend Verification Email
                                </button>
                                <button
                                    onClick={() => setIsEmailVerificationPopupOpen(false)}
                                    className="artifact-cancel-button"
                                    aria-label="Close verification popup"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PaymentModal;
