import React, { useRef, useEffect, useMemo, useState, useCallback } from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import "../style/FileExplorerModal.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteFileFromLibrary, updateUserFile, updateUserFiles, setPopupMessage } from "../store/user.js";
import { renameFileGlobal, deleteProjectFile, updateModal, addFileToProject, updateArtifact } from "../store/project.js";
import { downloadFiles, updateFileProperties } from "../api.js";
import Select from 'react-select';

// Custom filter function for case-insensitive file name search
const fileNameFilter = (rows, id, filterValue) => {
    return rows.filter(row => {
        const rowValue = row.values[id];
        return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .includes(String(filterValue).toLowerCase())
            : true;
    });
};

function FileExplorerModal() {

    const [editingFile, setEditingFile] = useState(null);
    const [deletingFiles, setDeletingFiles] = useState(null);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const modalRef = useRef(null);
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.data);
    const { currentProjectId, projectList, currentProject, modal } = useSelector((state) => state.project);
    const [mouseOutsideDropdown, setMouseOutsideDropdown] = useState(false);
    const token = useSelector(state => state.user.access_token);
    const [rerender, setRerender] = useState(false); // Add this line
    const [isFileUploadInProgress, setIsFileUploadInProgress] = useState(false);
    const isLoadingFiles = useSelector((state) => state.project.isLoadingFiles);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [topologyState, setTopologyState] = useState({});
    const [parentFileState, setParentFileState] = useState({});
    const [isDeletingInProgress, setIsDeletingInProgress] = useState(false);
    const selectAllCheckboxRef = useRef(null);

    const closeDropdownWithDelay = useCallback(() => {
        const timer = setTimeout(() => {
            if (mouseOutsideDropdown) {
                setActiveDropdown(null);
            }
        }, 100); // 100ms delay

        return () => clearTimeout(timer);
    }, [mouseOutsideDropdown]);

    useEffect(() => {
        const handleMouseMove = (event) => {
            if (activeDropdown && dropdownRef.current) {
                const dropdownRect = dropdownRef.current.getBoundingClientRect();
                const isOutside =
                    event.clientX < dropdownRect.left ||
                    event.clientX > dropdownRect.right ||
                    event.clientY < dropdownRect.top ||
                    event.clientY > dropdownRect.bottom;

                setMouseOutsideDropdown(isOutside);
            }
        };

        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [activeDropdown]);

    useEffect(() => {
        if (mouseOutsideDropdown) {
            return closeDropdownWithDelay();
        }
    }, [mouseOutsideDropdown, closeDropdownWithDelay]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                !isLoadingFiles &&
                modalRef.current &&
                !modalRef.current.contains(event.target) &&
                !event.target.closest('.file-dropdown') &&
                !event.target.closest('.file-upload-modal-dialog')
            ) {
                if (!deletingFiles) {
                    if (modal.name === 'upload_general' || modal.name === 'upload_project') {
                        if (modal.name === 'upload_general') {
                            dispatch(updateModal({ name: 'dna_archive_general', data: null }));
                        } else if (modal.name === 'upload_project') {
                            dispatch(updateModal({ name: 'dna_archive_project', data: null }));
                        }
                    } else if (modal.name === 'dna_archive_general' || modal.name === 'dna_archive_project') {
                        dispatch(updateModal({ name: '', data: null }));
                    }
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [deletingFiles, dispatch, modal.name, isLoadingFiles]);

    useEffect(() => {
        if (editingFile && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editingFile]);

    const handleFileRename = (file) => {
        setEditingFile(file);
        setActiveDropdown(null);
    };

    const handleFileDownload = (file) => {
        console.log("Downloading file:", file);
        downloadFiles(file.id, "file", token);
        setActiveDropdown(null);
    };

    const handleDelete = (file) => {
        setSelectedFiles([file.id]);
        handleDeleteSelected();
        setActiveDropdown(null);
    };

    const handleFileRenameSubmit = async (file, newName) => {
        try {
            if (!user || !user.user_id) {
                throw new Error("User ID is not available");
            }

            // Get the extensions
            const oldExtension = file.name.split('.').pop().toLowerCase();
            const newExtension = newName.split('.').pop().toLowerCase();

            // Check if the new name has the same extension
            if (newExtension !== oldExtension) {
                dispatch(setPopupMessage("Error: File extension must remain the same"));
                setEditingFile(null);
                return;
            }

            dispatch(
                renameFileGlobal({
                    fileId: file.id,
                    newName,
                    oldName: file.name,
                    userId: user.user_id,
                })
            );

            dispatch(updateUserFiles({ fileId: file.id, newName }));

            setEditingFile(null);
        } catch (error) {
            console.error("Error renaming file:", error);
            dispatch(setPopupMessage("Error renaming file: " + error.message));
            setEditingFile(null);
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            setIsDeletingInProgress(true);
            if (!user || !user.user_id) {
                throw new Error("User ID is not available");
            }

            const deleteFileRecursively = async (fileId) => {
                const file = user.files[fileId];
                if (!file) return;

                // Recursively delete child files
                for (const childId of file.child_file_ids) {
                    console.log("deleting child file", childId);
                    await deleteFileRecursively(childId);
                }

                // Delete from project if it exists in the current project
                if (currentProjectId && currentProject && currentProject.files && currentProject.files[fileId]) {
                    try {
                        await dispatch(deleteProjectFile({
                            projectId: currentProjectId,
                            fileId: fileId,
                        })).unwrap();
                    } catch (error) {
                        console.error("Error deleting file from project:", error);
                    }
                }

                // Delete from library
                await dispatch(deleteFileFromLibrary({
                    fileIds: [fileId],
                    fileNames: [file.file_name],
                    userId: user.user_id,
                })).unwrap();
            };

            // Delete each selected file and its children
            for (const fileId of selectedFiles) {
                await deleteFileRecursively(fileId);
            }

            setIsDeletingInProgress(false);
            setDeletingFiles(null);
            setSelectedFiles([]);
        } catch (error) {
            console.error("Error deleting files:", error);
            setIsDeletingInProgress(false);
            setDeletingFiles(null);
        }
    };

    const toggleDropdown = (event, fileId) => {
        event.stopPropagation();
        setActiveDropdown((prevActiveDropdown) =>
            prevActiveDropdown === fileId ? null : fileId
        );
    };

    // const handleView = (file) => {
    //     // This function will be implemented later
    //     // Close the modal
    //     dispatch(updateModal({ name: null, data: null }));

    //     // Open the artifact panel and populate with the file info
    //     console.log("file", file);
    //     dispatch(updateArtifacts(file));
    //     setActiveDropdown(null);
    // };


    const handleAddFile = useCallback((file) => {
        dispatch(addFileToProject({ fileId: file.id, projectId: currentProjectId }));
    }, [dispatch, currentProjectId]);

    const formatFileSize = (bytes) => {
        if (bytes < 1024) {
            return `${bytes} bytes`;
        } else if (bytes < 1024 * 1024) {
            return `${Math.floor(bytes / 1024)} KB`;
        } else {
            return `${Math.floor(bytes / (1024 * 1024))} MB`;
        }
    };

    const data = useMemo(() => {
        if (!user || !user.files || !projectList) return [];
        return Object.values(user.files)
            .filter(file => {
                if (file.file_type !== "DNA") return false;
                if (file.part_source === "digest") {
                    return file.project_ids.includes(currentProjectId);
                }
                return file.part_source !== "digest";
            })
            .map((file) => {
                const projectNames = projectList
                    .filter((project) => project.files && project.files[file.dna_id])
                    .map((project) => project.project_name)
                    .join(", ");

                return {
                    id: file.dna_id,
                    name: file.file_name,
                    size: formatFileSize(file.file_size),
                    numSequences: file.number_of_sequences,
                    projectNames: projectNames || "N/A",
                    uploadDate: new Date(file.upload_date).toLocaleDateString(),
                    topology: file.sequence_topology,
                    parentFile: file.parent_file_id,
                };
            });
    }, [user, projectList, currentProjectId]);

    const handleSelectFile = (fileId) => {
        setSelectedFiles((prevSelectedFiles) =>
            prevSelectedFiles.includes(fileId)
                ? prevSelectedFiles.filter((id) => id !== fileId)
                : [...prevSelectedFiles, fileId]
        );
    };

    const handleDownloadSelected = () => {
        selectedFiles.forEach((fileId) => {
            const file = data.find((file) => file.id === fileId);
            if (file) {
                downloadFiles(file.id, "file", token);
            }
        });
        setSelectedFiles([]);
    };

    const handleDeleteSelected = () => {
        setDeletingFiles(selectedFiles.map(fileId => data.find(file => file.id === fileId)));
    };

    const handleTopologyChange = useCallback(async (fileId, newTopology) => {
        try {
            setTopologyState(prev => ({ ...prev, [fileId]: newTopology }));
            const updatedFile = await updateFileProperties(fileId, { topology: newTopology }, user.user_id, token);

            // Dispatch the updateUserFile action with the updated file
            dispatch(updateUserFile({ fileId, updatedFile }));
        } catch (error) {
            console.error('Error updating file topology:', error);
            // Revert the state if there's an error
            setTopologyState(prev => ({ ...prev, [fileId]: user.files[fileId].sequence_topology }));
        }
    }, [user.user_id, token, dispatch, user.files]);

    const handleParentFileChange = useCallback(async (fileId, newParentFileId) => {
        try {
            setParentFileState(prev => ({ ...prev, [fileId]: newParentFileId }));
            const updatedFile = await updateFileProperties(fileId, { parent_file_id: newParentFileId || null }, user.user_id, token);

            // Dispatch the updateUserFile action with the updated file
            dispatch(updateUserFile({ fileId, updatedFile }));
        } catch (error) {
            console.error('Error updating file parent:', error);
            // Revert the state if there's an error
            setParentFileState(prev => ({ ...prev, [fileId]: user.files[fileId].parent_file_id }));
        }
    }, [user.user_id, token, dispatch, user.files]);

    // Add keyboard handler for dropdown
    const handleKeyDown = (event, fileId) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            toggleDropdown(event, fileId);
        }
    };

    // Add keyboard handler for dropdown items
    const handleDropdownKeyDown = (event, action, file) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            switch (action) {
                case 'rename':
                    handleFileRename(file);
                    break;
                case 'download':
                    handleFileDownload(file);
                    break;
                case 'delete':
                    handleDelete(file);
                    break;
                default:
                    break;
            }
        }
    };

    const baseColumns = useMemo(() => {
        // Start with the base columns array
        const columns = [
            {
                Header: () => (
                    <input
                        ref={selectAllCheckboxRef}
                        type="checkbox"
                        aria-label="Select all files"
                    />
                ),
                id: "select",
                Cell: ({ row }) => (
                    <input
                        type="checkbox"
                        checked={selectedFiles.includes(row.original.id)}
                        onChange={() => handleSelectFile(row.original.id)}
                        aria-label={`Select ${row.original.name}`}
                    />
                ),
            },
            {
                Header: "Name",
                accessor: "name",
                maxWidth: 437,
                Cell: ({ row }) =>
                    editingFile && editingFile.id === row.original.id ? (
                        <input
                            ref={inputRef}
                            type="text"
                            defaultValue={row.original.name}
                            onBlur={(e) => handleFileRenameSubmit(row.original, e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    handleFileRenameSubmit(row.original, e.target.value);
                                }
                            }}
                            aria-label={`Rename ${row.original.name}`}
                        />
                    ) : (
                        <div className="file-name-cell">
                            {row.original.name}
                        </div>
                    ),
                filter: fileNameFilter,
            },
            {
                Header: "Sequences",
                accessor: "numSequences",
            },
            {
                Header: "Projects",
                accessor: "projectNames",
                maxWidth: 350,
                Cell: ({ value }) => (
                    <div className="projects-cell">
                        {value}
                    </div>
                ),
            },
            {
                Header: "Upload Date",
                accessor: "uploadDate",
            },
            {
                Header: "Topology",
                accessor: "topology",
                Cell: ({ row }) => (
                    <select
                        value={topologyState[row.original.id] || row.original.topology}
                        onChange={(e) => handleTopologyChange(row.original.id, e.target.value)}
                        className="topology-select"
                        aria-label={`Topology for ${row.original.name}`}
                    >
                        <option value="circular">Circular</option>
                        <option value="linear">Linear</option>
                    </select>
                ),
            },
            {
                Header: "",
                id: "actions",
                Cell: ({ row }) => (
                    <div className="file-actions">
                        <button
                            className="file-menu-button"
                            onClick={(event) => toggleDropdown(event, row.original.id)}
                            onKeyDown={(event) => handleKeyDown(event, row.original.id)}
                            aria-label={`Actions for ${row.original.name}`}
                            aria-expanded={activeDropdown === row.original.id}
                            aria-haspopup="true"
                        >
                            <img
                                src="/images/three-dots.svg"
                                alt=""
                                className="file-menu-icon-always-visible"
                                aria-hidden="true"
                            />
                        </button>
                        {activeDropdown === row.original.id && (
                            <div
                                ref={dropdownRef}
                                className="file-dropdown"
                                role="menu"
                                aria-label={`Actions for ${row.original.name}`}
                                onMouseEnter={() => setMouseOutsideDropdown(false)}
                            >
                                <button
                                    className="dropdown-button"
                                    onClick={() => handleFileRename(row.original)}
                                    onKeyDown={(e) => handleDropdownKeyDown(e, 'rename', row.original)}
                                    role="menuitem"
                                >
                                    <span>Rename</span>
                                    <img
                                        src="/images/pencil.svg"
                                        alt=""
                                        className="dropdown-icon"
                                        aria-hidden="true"
                                    />
                                </button>
                                <button
                                    className="dropdown-button"
                                    onClick={() => handleFileDownload(row.original)}
                                    onKeyDown={(e) => handleDropdownKeyDown(e, 'download', row.original)}
                                    role="menuitem"
                                >
                                    <span>Download</span>
                                    <img
                                        src="/images/download.svg"
                                        alt=""
                                        className="dropdown-icon"
                                        aria-hidden="true"
                                    />
                                </button>
                                <button
                                    className="dropdown-button"
                                    onClick={() => handleDelete(row.original)}
                                    onKeyDown={(e) => handleDropdownKeyDown(e, 'delete', row.original)}
                                    role="menuitem"
                                >
                                    <span>Delete</span>
                                    <img
                                        src="/images/trash.svg"
                                        alt=""
                                        className="dropdown-icon"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        )}
                    </div>
                ),
            },
        ];

        // Add the "add" column at the beginning if in project mode
        if (modal.name.endsWith('_project')) {
            columns.unshift({
                Header: "",
                id: "add",
                Cell: ({ row }) => (
                    <div className="add-file-action">
                        <img
                            src="/images/plus.svg"
                            alt="Add"
                            className="add-file-icon"
                            onClick={() => handleAddFile(row.original)}
                        />
                    </div>
                ),
            });
        }

        return columns;
    }, [editingFile, activeDropdown, modal.name, handleAddFile, selectedFiles, data, user.files, token, dispatch, handleTopologyChange, topologyState]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns: baseColumns,
            data,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useFilters,
        useSortBy,
        usePagination
    );

    const allPageIds = page.map(row => row.original.id);
    const areAllPageItemsSelected = allPageIds.every(id => selectedFiles.includes(id));
    const areSomePageItemsSelected = allPageIds.some(id => selectedFiles.includes(id));

    const handleSelectAllFiles = useCallback(() => {
        const allPageIds = page.map(row => row.original.id);
        const areAllSelected = allPageIds.every(id => selectedFiles.includes(id));

        setSelectedFiles(prevSelectedFiles =>
            areAllSelected
                ? prevSelectedFiles.filter(id => !allPageIds.includes(id))
                : [...new Set([...prevSelectedFiles, ...allPageIds])]
        );
    }, [selectedFiles, page]);

    useEffect(() => {
        if (selectAllCheckboxRef.current) {
            selectAllCheckboxRef.current.checked = areAllPageItemsSelected;
            selectAllCheckboxRef.current.indeterminate = areSomePageItemsSelected && !areAllPageItemsSelected;
        }
    }, [areSomePageItemsSelected, areAllPageItemsSelected]);

    useEffect(() => {
        setSelectedFiles([]);
    }, [pageIndex, pageSize, searchTerm, modal.name]);

    useEffect(() => {
        if (selectAllCheckboxRef.current) {
            selectAllCheckboxRef.current.onclick = handleSelectAllFiles;
        }
    }, [handleSelectAllFiles]);

    useEffect(() => {
        setFilter("name", searchTerm);
    }, [searchTerm, setFilter]);

    useEffect(() => {
        if (modal.isFileUploading !== undefined) {
            setIsFileUploadInProgress(modal.isFileUploading);
        }
    }, [modal, deletingFiles, dispatch]);

    const handleAddSelectedToProject = useCallback(async () => {
        try {
            // Process files sequentially
            for (const fileId of selectedFiles) {
                await dispatch(addFileToProject({ fileId, projectId: currentProjectId })).unwrap();
            }
            // Only clear selection after all files have been added
            setSelectedFiles([]);
        } catch (error) {
            console.error('Error adding files to project:', error);
        }
    }, [dispatch, selectedFiles, currentProjectId]);

    return (
        <div
            className={`file-explorer-modal-overlay ${isLoadingFiles ? 'loading' : ''}`}
            onClick={(e) => e.stopPropagation()}
            role="dialog"
            aria-label="DNA Sequences File Explorer"
        >
            <div className="file-explorer-modal-content" ref={modalRef}>
                <h2 id="modal-title">DNA Sequences</h2>
                <div className="search-and-upload-container">
                    <div className="search-bar-container">
                        <div className="search-bar" role="search">
                            <img src="/images/search.svg" alt="Search" className="search-icon" />
                            <input
                                id="file-search"
                                type="text"
                                placeholder="Search files..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                aria-label="Search files"
                            />
                        </div>
                    </div>
                    <div className="multi-action-buttons">
                        {currentProject && (
                            <button
                                onClick={handleAddSelectedToProject}
                                className="multi-action-button"
                                disabled={selectedFiles.length === 0}
                                aria-label={`Add ${selectedFiles.length} selected files to ${currentProject.project_name}`}
                            >
                                Add Files To {currentProject.project_name}
                            </button>
                        )}
                        <button
                            onClick={handleDownloadSelected}
                            className="multi-action-button"
                            disabled={selectedFiles.length === 0}
                            aria-label={`Download ${selectedFiles.length} selected files`}
                        >
                            Download Selected
                        </button>
                        <button
                            onClick={handleDeleteSelected}
                            className="multi-action-button"
                            disabled={selectedFiles.length === 0}
                            aria-label={`Delete ${selectedFiles.length} selected files`}
                        >
                            Delete Selected
                        </button>
                    </div>
                    <button
                        onClick={() => {
                            dispatch(updateModal({ name: modal.name.replace('dna_archive', 'upload'), data: null }));
                        }}
                        className="upload-file-button"
                    >
                        {modal.name.endsWith('_general')
                            ? "Upload File"
                            : modal.name.endsWith('_project') && currentProject
                                ? `Upload File To ${currentProject.project_name}`
                                : "Upload File"}
                    </button>
                </div>
                <div className="table-container" role="region" aria-label="Files table">
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup, index) => (
                                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                    {headerGroup.headers.map((column, colIndex) => (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            key={colIndex}
                                        >
                                            {column.render("Header")}
                                            <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? " 🔽"
                                                        : " 🔼"
                                                    : ""}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, rowIndex) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} key={rowIndex}>
                                        {row.cells.map((cell, cellIndex) => (
                                            <td {...cell.getCellProps()} key={cellIndex}>
                                                {cell.render("Cell")}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="pagination" role="navigation" aria-label="Pagination">
                    <div className="pagination-controls">
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            {"<<"}
                        </button>
                        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            {"<"}
                        </button>
                        <button onClick={() => nextPage()} disabled={!canNextPage}>
                            {">"}
                        </button>
                        <button
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                        >
                            {">>"}
                        </button>
                        <span>
                            Page{" "}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{" "}
                        </span>
                        <span>
                            | Go to page:{" "}
                            <input
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={(e) => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                    gotoPage(page);
                                }}
                                style={{ width: "50px" }}
                            />
                        </span>{" "}
                        <select
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                            }}
                        >
                            {[10, 20, 30, 40, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            {deletingFiles && (
                <div className="delete-confirmation-overlay">
                    <div className="delete-confirmation-dialog">
                        <h3>{isDeletingInProgress ? "Deletion in Progress" : "Confirm Deletion"}</h3>
                        {isDeletingInProgress ? (
                            <p>Deleting selected file(s). Please wait...</p>
                        ) : (
                            <>
                                <p>
                                    Are you sure you want to permanently delete the selected file(s)?
                                </p>
                                <p>This action cannot be undone.</p>
                                <div className="delete-confirmation-buttons">
                                    <button
                                        onClick={handleDeleteConfirm}
                                        className="delete-confirm-button"
                                    >
                                        Yes, Delete
                                    </button>
                                    <button
                                        onClick={() => setDeletingFiles(null)}
                                        className="delete-cancel-button"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
            {isFileUploadInProgress && (
                <div className="file-upload-loading-overlay">
                    <div className="file-upload-loading-spinner"></div>
                </div>
            )}
        </div>
    );
}

export default FileExplorerModal;