import React, { useState, useEffect } from "react";
import "../style/ManageGroup.css";
import { useSelector, useDispatch } from "react-redux";
import { addMemberToGroup } from "../store/user.js";
import { deleteGroupThunk, removeGroupMemberThunk, changeGroupOwnerThunk, transferCreditsToGroupThunk, transferCreditsFromGroupThunk, renameGroupThunk, leaveGroupThunk } from "../store/user.js";

const ManageGroup = ({ group, onClose }) => {
    const [showRename, setShowRename] = useState(false);
    const [newName, setNewName] = useState(group.name);
    const [showAddMember, setShowAddMember] = useState(false);
    const [newEmail, setNewEmail] = useState("");
    const [creditsToAdd, setCreditsToAdd] = useState("");
    const [newOwner, setNewOwner] = useState("");
    const currentUserId = useSelector(state => state.user.user_id);
    const isOwner = group.owner === currentUserId;
    const [creditsToModify, setCreditsToModify] = useState("");
    const [showAddCredits, setShowAddCredits] = useState(false);
    const [showRemoveCredits, setShowRemoveCredits] = useState(false);
    const [showAddMemberForm, setShowAddMemberForm] = useState(false);
    const [showRemoveMemberForm, setShowRemoveMemberForm] = useState(false);
    const [showChangeOwner, setShowChangeOwner] = useState(false);
    const [activeAction, setActiveAction] = useState(null);
    const totalUsageCredits = useSelector(state => state.user.usageCredits) || 0;
    const groupsObj = useSelector(state => state.user.groups || {});
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [deleteConfirmValue, setDeleteConfirmValue] = useState("");
    const [isDeletingInProgress, setIsDeletingInProgress] = useState(false);

    // Calculate available credits by subtracting all group credits from total
    const groupCreditsSum = Object.values(groupsObj).reduce((sum, group) => sum + (group.num_credits || 0), 0);
    const availableCredits = totalUsageCredits - groupCreditsSum;

    const dispatch = useDispatch();

    // Add useEffect for success message timeout
    useEffect(() => {
        let timer;
        if (successMessage) {
            timer = setTimeout(() => {
                setSuccessMessage("");
            }, 5000); // 5 second timeout
        }
        return () => clearTimeout(timer);
    }, [successMessage]);

    const handleRename = async (e) => {
        e.preventDefault();
        try {
            await dispatch(renameGroupThunk({ 
                groupId: group.group_id, 
                newName 
            })).unwrap();
            setShowRename(false);
            setSuccessMessage("Group name updated successfully");
            handleActionClick('rename'); // Close the rename form
        } catch (error) {
            console.error('Failed to rename group:', error);
            setErrorMessage("Failed to rename group");
        }
    };

    const handleDeleteGroup = async (e) => {
        e.preventDefault();
        const confirmText = "delete";
        
        if (deleteConfirmValue !== confirmText) {
            setErrorMessage(`Please type "${confirmText}" to confirm.`);
            return;
        }
        
        try {
            setIsDeletingInProgress(true);
            await dispatch(deleteGroupThunk(group.group_id)).unwrap();
            onClose();
        } catch (error) {
            console.error('Failed to delete group:', error);
            setErrorMessage("Failed to delete group");
        } finally {
            setIsDeletingInProgress(false);
            setShowDeleteConfirm(false);
        }
    };

    const handleAddMember = async (e) => {
        e.preventDefault();
        if (newEmail) {
            try {
                await dispatch(addMemberToGroup({ 
                    groupId: group.group_id, 
                    email: newEmail 
                })).unwrap();
                setNewEmail("");
                setShowAddMemberForm(false);
                setErrorMessage("");
            } catch (error) {
                console.error('Failed to add member:', error);
                setErrorMessage(error.message || "Failed to add member to group. Please ensure they have already signed up with the same email.");
            }
        }
    };

    const handleRemoveMember = async (email) => {
        if (window.confirm(`Remove ${email} from the group?`)) {
            try {
                await dispatch(removeGroupMemberThunk({
                    groupId: group.group_id,
                    userEmail: email
                })).unwrap();
            } catch (error) {
                console.error('Failed to remove member:', error);
            }
        }
    };

    const handleLeaveGroup = async () => {
        if (window.confirm("Are you sure you want to leave this group?")) {
            try {
                const userEmail = Object.entries(group.member_ids)
                    .find(([id]) => id === currentUserId.toString())?.[1];
                    
                if (userEmail) {
                    await dispatch(leaveGroupThunk({
                        groupId: group.group_id,
                        userEmail: userEmail
                    })).unwrap();
                    onClose();
                }
            } catch (error) {
                console.error('Failed to leave group:', error);
            }
        }
    };

    const handleChangeOwner = async (e) => {
        e.preventDefault();
        try {
            await dispatch(changeGroupOwnerThunk({
                groupId: group.group_id,
                newOwnerEmail: newOwner
            })).unwrap();
            setNewOwner("");
            handleActionClick('owner');
        } catch (error) {
            console.error('Failed to change owner:', error);
        }
    };

    const handleAddCredits = async (e) => {
        e.preventDefault();
        if (creditsToModify && parseInt(creditsToModify) > 0) {
            try {
                await dispatch(transferCreditsToGroupThunk({
                    groupId: group.group_id,
                    amount: parseInt(creditsToModify)
                })).unwrap();
                setCreditsToModify("");
                setShowAddCredits(false);
            } catch (error) {
                console.error('Failed to transfer credits:', error);
            }
        }
    };

    const handleRemoveCredits = async (e) => {
        e.preventDefault();
        const credits = parseInt(creditsToModify);
        if (credits > 0 && credits <= group.num_credits) {
            try {
                await dispatch(transferCreditsFromGroupThunk({
                    groupId: group.group_id,
                    amount: credits
                })).unwrap();
                setCreditsToModify("");
                setShowRemoveCredits(false);
            } catch (error) {
                console.error('Failed to reclaim credits:', error);
            }
        }
    };

    const isOwnerInGroup = Object.keys(group.member_ids).includes(group.owner);

    const handleActionClick = (action) => {
        if (activeAction === action) {
            setActiveAction(null);
            if (action === 'rename') setNewName(group.name);
            if (action === 'owner') setNewOwner('');
        } else {
            setActiveAction(action);
            setNewName(group.name);
            setNewOwner('');
        }
    };

    const handleBack = () => {
        onClose();
    };

    return (
        <div 
            className="manage-modal-overlay" 
            onClick={onClose}
            role="dialog"
            aria-modal="true"
            aria-labelledby="manage-modal-title"
        >
            <div 
                className="manage-modal-content" 
                onClick={e => e.stopPropagation()}
                role="document"
            >
                {successMessage && (
                    <div 
                        className="manage-success-message" 
                        role="alert"
                        aria-live="polite"
                    >
                        <p>{successMessage}</p>
                    </div>
                )}
                <div className="manage-modal-header">
                    <h2 className="manage-modal-title" id="manage-modal-title">
                        {showRename ? "Rename Group" : `${group.name}`}
                    </h2>
                    <button 
                        className="manage-back-button" 
                        onClick={handleBack}
                        aria-label="Back to Groups"
                    >
                        ← Back to Groups
                    </button>
                </div>
                <hr className="manage-modal-divider" />

                <div className="manage-section">
                    <div className="manage-header">
                        <h3 id="members-heading">Members ({group.member_count})</h3>
                        {isOwner && (
                            <button
                                className="action-button"
                                onClick={() => setShowAddMemberForm(true)}
                                aria-label="Add New Member"
                            >
                                Add Member
                            </button>
                        )}
                    </div>

                    <div className="member-list" role="list" aria-labelledby="members-heading">
                        {Object.entries(group.member_ids).map(([userId, email]) => (
                            <div key={userId} className="member-item" role="listitem">
                                <span>{email} {userId === group.owner && "(owner)"}</span>
                                {isOwner && userId !== currentUserId && (
                                    <button
                                        className="remove-icon"
                                        onClick={() => handleRemoveMember(email)}
                                        aria-label={`Remove ${email} from group`}
                                    >
                                        <img
                                            src="/images/trash.svg"
                                            alt=""
                                            role="presentation"
                                        />
                                    </button>
                                )}
                                {!isOwner && userId === currentUserId && (
                                    <button
                                        className="leave-button"
                                        onClick={handleLeaveGroup}
                                        aria-label="Leave this group"
                                    >
                                        Leave Group
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>

                    {showAddMemberForm && (
                        <form 
                            onSubmit={handleAddMember} 
                            className="manage-form"
                            aria-label="Add member form"
                        >
                            <label htmlFor="new-member-email">Member Email</label>
                            <input
                                id="new-member-email"
                                type="email"
                                value={newEmail}
                                onChange={(e) => setNewEmail(e.target.value)}
                                placeholder="Enter member email"
                                aria-invalid={errorMessage ? "true" : "false"}
                                aria-describedby={errorMessage ? "add-member-error" : undefined}
                                autoFocus
                            />
                            {errorMessage && (
                                <div 
                                    id="add-member-error"
                                    className="manage-group-error-message"
                                    role="alert"
                                >
                                    {errorMessage}
                                </div>
                            )}
                            <div className="form-buttons">
                                <button type="submit">Add</button>
                                <button 
                                    type="button" 
                                    onClick={() => {
                                        setShowAddMemberForm(false);
                                        setErrorMessage("");
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    )}
                </div>

                {isOwner && (
                    <div className="manage-section">
                        <div className="manage-header">
                            <h3>Credits ({group.num_credits})</h3>
                            <div className="manage-buttons">
                                <button 
                                    className="action-button"
                                    onClick={() => {
                                        setShowAddCredits(true);
                                        setShowRemoveCredits(false);
                                    }}
                                >
                                    Transfer to Group
                                </button>
                                <button 
                                    className="action-button"
                                    onClick={() => {
                                        setShowRemoveCredits(true);
                                        setShowAddCredits(false);
                                    }}
                                >
                                    Reclaim Credits
                                </button>
                            </div>
                        </div>

                        {(showAddCredits || showRemoveCredits) && (
                            <form 
                                onSubmit={showAddCredits ? handleAddCredits : handleRemoveCredits} 
                                className="manage-form"
                            >
                                <div className="credits-input-container">
                                    <input
                                        type="number"
                                        min="1"
                                        max={showRemoveCredits ? group.num_credits : availableCredits}
                                        value={creditsToModify}
                                        onChange={(e) => setCreditsToModify(e.target.value)}
                                        placeholder={showAddCredits ? 
                                            "Credits to transfer to group" : 
                                            "Credits to reclaim from group"
                                        }
                                    />
                                    {showAddCredits && (
                                        <span className="credits-available">
                                            {availableCredits} personal credits available to transfer
                                        </span>
                                    )}
                                    {showRemoveCredits && (
                                        <span className="credits-available">
                                            {group.num_credits} group credits available to reclaim
                                        </span>
                                    )}
                                </div>
                                <div className="form-buttons">
                                    <button type="submit">
                                        {showAddCredits ? 'Transfer' : 'Reclaim'}
                                    </button>
                                    <button 
                                        type="button" 
                                        onClick={() => {
                                            setShowAddCredits(false);
                                            setShowRemoveCredits(false);
                                            setCreditsToModify("");
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                )}

                {isOwner && (
                    <div className="manage-section">
                        <div className="manage-header">
                            <h3>Group Actions</h3>
                        </div>
                        <div className="group-actions">
                            <button
                                className={`action-button ${activeAction === 'rename' ? 'active' : ''}`}
                                onClick={() => handleActionClick('rename')}
                            >
                                Rename Group
                            </button>
                            {isOwnerInGroup && (
                                <button
                                    className={`action-button ${activeAction === 'owner' ? 'active' : ''}`}
                                    onClick={() => handleActionClick('owner')}
                                >
                                    Change Owner
                                </button>
                            )}
                            <button
                                className="delete-button"
                                onClick={() => setShowDeleteConfirm(true)}
                            >
                                Delete Group
                            </button>
                        </div>

                        {showDeleteConfirm && (
                            <form onSubmit={handleDeleteGroup} className="manage-form delete-confirm-form">
                                <p>Are you sure you want to delete this group? This action cannot be undone.</p>
                                <input
                                    type="text"
                                    value={deleteConfirmValue}
                                    onChange={(e) => setDeleteConfirmValue(e.target.value)}
                                    placeholder='Type "delete" to confirm'
                                    className="delete-confirm-input"
                                    autoFocus
                                />
                                {errorMessage && (
                                    <div className="manage-group-error-message">
                                        {errorMessage}
                                    </div>
                                )}
                                <div className="form-buttons">
                                    <button 
                                        type="submit" 
                                        className="delete-confirm-button"
                                        disabled={isDeletingInProgress}
                                    >
                                        {isDeletingInProgress ? 'Deleting...' : 'Delete'}
                                    </button>
                                    <button 
                                        type="button" 
                                        onClick={() => {
                                            setShowDeleteConfirm(false);
                                            setDeleteConfirmValue("");
                                            setErrorMessage("");
                                        }}
                                        className="delete-cancel-button"
                                        disabled={isDeletingInProgress}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        )}

                        {activeAction === 'rename' && (
                            <form onSubmit={handleRename} className="manage-form">
                                <input
                                    type="text"
                                    value={newName}
                                    onChange={(e) => setNewName(e.target.value)}
                                    placeholder="New group name"
                                    autoFocus
                                />
                                <div className="form-buttons">
                                    <button type="submit">Save</button>
                                    <button type="button" onClick={() => handleActionClick('rename')}>Cancel</button>
                                </div>
                            </form>
                        )}

                        {activeAction === 'owner' && (
                            <form onSubmit={handleChangeOwner} className="manage-form">
                                <input
                                    type="email"
                                    value={newOwner}
                                    onChange={(e) => setNewOwner(e.target.value)}
                                    placeholder="New owner email"
                                    autoFocus
                                />
                                <div className="form-buttons">
                                    <button
                                        type="submit"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (window.confirm(`Are you sure you want to transfer ownership to ${newOwner}? This action cannot be undone.`)) {
                                                handleChangeOwner(e);
                                                handleActionClick('owner');
                                            }
                                        }}
                                    >
                                        Transfer
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => handleActionClick('owner')}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ManageGroup;
