import React, { useState, useEffect, useRef } from "react";
import { renameExistingProject, updateModal, closeModal } from "../store/project.js";
import { useDispatch, useSelector } from "react-redux";
import "../style/Modal.css";  // Import the CSS file

function RenameProjectModal({ isOpen, initialValue = "" }) {
    const [inputValue, setInputValue] = useState(initialValue || "");
    const [error, setError] = useState("");
    const modalRef = useRef(null);
    const dispatch = useDispatch();
    const { modal } = useSelector((state) => state.project);
    const projectList = useSelector((state) => state.project.projectList);

    const title = "Rename Project";

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target)
            ) {
                dispatch(updateModal({ name: '', data: null }));
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dispatch]);

    useEffect(() => {
        setInputValue(initialValue || "");
    }, [initialValue]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                dispatch(closeModal());
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [dispatch]);

    const handleRenameProjectSubmit = async (newName) => {
        try {
            const existingProject = projectList.find(project => project.project_name === newName);
            if (existingProject) {
                setError("Project with this name already exists");
                return;
            }
            dispatch(renameExistingProject({ projectId: modal.data.project_id, newName }));
            dispatch(closeModal());
        } catch (error) {
            console.error("Error renaming project:", error);
        }
    };

    return (
        <div 
            className="rename-project-modal-overlay" 
            onClick={(e) => e.stopPropagation()}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-title"
        >
            <div 
                className="rename-project-modal-content" 
                ref={modalRef}
            >
                <h2 
                    className="rename-project-modal-title" 
                    id="modal-title"
                >
                    {title}
                </h2>
                {error && (
                    <div 
                        className="modal-error-message" 
                        role="alert"
                        aria-live="polite"
                    >
                        {error}
                    </div>
                )}
                <form 
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleRenameProjectSubmit(inputValue);
                    }}
                    aria-label="Rename project form"
                >
                    <input
                        id="project-name-input"
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder="Enter new name"
                        className="rename-project-input"
                        aria-invalid={!!error}
                        aria-describedby={error ? "name-error" : undefined}
                    />
                    <div className="rename-project-modal-buttons">
                        <button 
                            type="button" 
                            onClick={() => dispatch(closeModal())} 
                            className="rename-project-cancel-button"
                        >
                            Cancel
                        </button>
                        <button 
                            type="submit" 
                            className="rename-project-submit-button"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default RenameProjectModal;
