const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const fetchWithToken = async (url, options = {}, token) => {
    const defaultHeaders = {
        'Content-Type': 'application/json',
    };

    if (token) {
        defaultHeaders['authorization'] = `Bearer ${token}`;
    }

    const mergedOptions = {
        ...options,
        headers: {
            ...defaultHeaders,
            ...options.headers,
        },
    };

    return fetch(url, mergedOptions);
};

export const handleLogin = async ({ token, user_auth0 }) => {
    try {
        console.log('handleLogin called with:', { user_auth0 });
        const response = await fetchWithToken(`${API_URL}/api/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_auth0: user_auth0 || null
            }),
            credentials: 'include',
        }, token);
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to handle Google login');
        }
        return response.json();
    } catch (error) {
        console.error('Error in handleLogin:', error);
        throw error;
    }
}

export const getCredential = async () => {
    try {
        console.log('getCredential called');
        const response = await fetch(`${API_URL}/api/get-credential`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error('Failed to fetch credential');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error in getCredential:', error);
        return null;
    }
}

export const createProject = async (projectName, user_id, token) => {
    console.log('createProject called with:', { projectName, user_id });
    const response = await fetchWithToken(`${API_URL}/api/create-project`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ project_name: projectName, user_id: user_id }),
    }, token);

    if (!response.ok) {
        throw new Error('Failed to create project');
    }

    const result = await response.json();
    return result.status === 'success' ? result.data : Promise.reject(result.message);
};

export const renameProject = async (projectId, newName, user_id, token) => {
    console.log('renameProject called with:', { projectId, newName, user_id });
    const response = await fetchWithToken(`${API_URL}/api/rename-project`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ project_id: projectId, new_name: newName, user_id: user_id }),
    }, token);

    if (!response.ok) {
        throw new Error('Failed to rename project');
    }

    const result = await response.json();
    return result.status === 'success' ? JSON.parse(result.data).projects : Promise.reject(result.message);
};

export const deleteProjectPermanent = async (projectId, user_id, token) => {
    console.log('deleteProjectPermanent called with:', { projectId, user_id });
    const response = await fetchWithToken(`${API_URL}/api/delete-project`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ project_id: projectId, user_id: user_id }),
    }, token);

    if (!response.ok) {
        throw new Error('Failed to delete project');
    }

    const result = await response.json();
    return result.status === 'success' ? JSON.parse(result.data).projects : Promise.reject(result.message);
};

export const downloadFiles = async (id, type, token) => {
    try {
        console.log('downloadFiles called with:', { id, type });
        const response = await fetchWithToken(`${API_URL}/api/download-project`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: id, type: type }),
        }, token);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        console.log('Response:', response);
        console.log('Headers:', [...response.headers.entries()]);

        const suggestedFilename = response.headers.get('X-Suggested-Filename');
        console.log('Suggested filename:', suggestedFilename);

        const contentType = response.headers.get('Content-Type');
        console.log('Content-Type:', contentType);

        if (type === 'file') {
            let fileData;
            if (contentType && contentType.includes('application/json')) {
                fileData = await response.json();
                console.log('JSON file data:', fileData);
            } else {
                fileData = await response.text();
                console.log('Text file data:', fileData);
            }

            const blob = new Blob([fileData], { type: contentType || 'application/octet-stream' });
            console.log('File Blob:', blob);

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = suggestedFilename || 'downloaded_file';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } else {
            const blob = await response.blob();
            console.log('Zip Blob:', blob);

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = suggestedFilename || 'project.zip';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }

        console.log('Download initiated');
        return 'Download initiated';
    } catch (error) {
        console.error('Error downloading project files:', error);
        throw error;
    }
};

const validateMessage = (message) => {
    // Create a temporary div to parse the HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = message;
    
    // Get text content excluding spans with class file-tag
    let cleanMessage = '';
    const walk = document.createTreeWalker(
        tempDiv,
        NodeFilter.SHOW_TEXT,
        {
            acceptNode: function(node) {
                // Skip if parent is a file-tag span
                if (node.parentElement && node.parentElement.classList && 
                    node.parentElement.classList.contains('file-tag')) {
                    return NodeFilter.FILTER_REJECT;
                }
                return NodeFilter.FILTER_ACCEPT;
            }
        }
    );

    while (walk.nextNode()) {
        cleanMessage += walk.currentNode.textContent;
    }

    // Check message length (2000 chars)
    console.log("message length (excluding file tags):", cleanMessage.length);
    if (cleanMessage.length > 2000) {
        throw {
            type: 'SendMessageError',
            message: 'Message is too long. Please keep messages under 2000 characters. Your previous chat and this response will be deleted when you send your next chat'
        };
    }

    // Check for long DNA sequences
    const dnaRegex = /[ATCG]{200,}/gi;
    const match = cleanMessage.match(dnaRegex);
    if (match) {
        throw {
            type: 'SendMessageError',
            message: 'DNA sequence detected in the chat. Please use the file upload feature for sequences. Your previous chat and this response will be deleted when you send your next chat'
        };
    }
};

export const sendMessage = async (message, chatId, projectId, currentProject, token) => {
    if (!message || !projectId) {
        throw new Error('Message and project ID are required');
    }

    // Add validation
    validateMessage(message);

    console.log('sendMessage called with:', { message, chatId, projectId, currentProject });
    const fileIds = extractFileIds(message);
    console.log("fileIds", fileIds);
    const containsNewFile = fileIds.some(fileId => !currentProject.files[fileId]);

    const response = await fetch(`${API_URL}/api/send-message`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            message,
            chat_id: chatId,
            project_id: projectId,
            contains_new_file: containsNewFile
        }),
    });

    if (!response.ok) {
        const errorData = await response.json();

        console.log("SEND MESSAGE ERROR", errorData);

        // Throw a custom error object with additional information
        throw {
            type: 'SendMessageError',
            message: errorData.message || 'Failed to send message',
            status: response.status
        };
    }

    return response; // Return the response object directly
};

function extractFileIds(message) {
    const regex = /<span[^>]*data-file-id="([^"]*)"[^>]*>/g;
    const fileIds = [];
    let match;
    while ((match = regex.exec(message)) !== null) {
        fileIds.push(match[1]);
    }
    return fileIds;
}

export const fetchChatMessages = async (projectId, token) => {
    console.log('fetchChatMessages called with:', { projectId });
    if (!projectId) {
        throw new Error('Project ID is required for fetching chat messages');
    }
    const response = await fetchWithToken(`${API_URL}/api/chat-messages/${projectId}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    }, token);

    if (!response.ok) {
        throw new Error('Failed to fetch chat messages');
    }

    const result = await response.json();
    console.log("chat data", result.data);

    return result.status === 'success' ? result.data : Promise.reject(result.message);
};

export const fetchDesignObject = async (projectId, token) => {
    console.log('fetchDesignObject called with:', { projectId });
    if (!projectId) {
        throw new Error('Project ID is required for fetching chat messages');
    }
    const response = await fetchWithToken(`${API_URL}/api/design-object/${projectId}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    }, token);

    if (!response.ok) {
        throw new Error('Failed to fetch chat messages');
    }

    const result = await response.json();
    console.log("design data", result.data);

    return result.status === 'success' ? result.data : Promise.reject(result.message);
};

export const uploadFiles = async (files, projectId, chatId, userId, annotate, token) => {
    if (!userId) {
        throw new Error('User ID is required for file upload');
    }

    const formData = new FormData();
    files.forEach((fileData, index) => {
        formData.append(`files`, fileData.file);
        formData.append(`topology_${index}`, fileData.topology);
    });
    formData.append('project_id', projectId || 'no_project');
    formData.append('chat_id', chatId);
    formData.append('user_id', userId);
    formData.append('annotate', annotate);

    const defaultHeaders = {
        'Authorization': `Bearer ${token}`
    };

    console.log("formData", formData);

    const response = await fetch(`${API_URL}/api/upload-files`, {
        method: 'POST',
        body: formData,
        headers: defaultHeaders,
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    if (result.status !== 'success') {
        throw new Error(`Upload failed: ${result.status}`);
    }

    return result.data;
};

export const fetchProjectList = async (userId, token) => {
    try {
        console.log('fetchProjectList called with:', { userId });
        const url = `${API_URL}/api/project-list`;
        const payload = { userId: userId };
        const response = await fetchWithToken(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        }, token);

        console.log("fetchProjectList response", response);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.status === 'success') {
            const projectsData = JSON.parse(data.data);
            if (Array.isArray(projectsData.projects)) {
                const response = projectsData.projects.map(project => ({
                    project_id: project.project_id,
                    user_id: project.user_id,
                    project_name: project.project_name,
                    chat_id: project.chat_id,
                    status: project.status,
                    files: project.files,
                    last_modified: project.last_modified
                }));
                return response;
            } else {
                throw new Error('Unexpected data format: projects is not an array');
            }
        } else {
            throw new Error(data.message || 'Server returned an error');
        }
    } catch (error) {
        console.error('Error in fetchProjectList:', error.message);
        throw error;
    }
};

export const deleteFileFromProject = async (projectId, fileUuid, token) => {
    console.log('deleteFileFromProject called with:', { projectId, fileUuid });
    const response = await fetchWithToken(`${API_URL}/api/delete-file`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ project_id: projectId, file_uuid: fileUuid }),
    }, token);

    if (!response.ok) {
        throw new Error('Failed to delete file');
    }

    const result = await response.json();
    return result.status === 'success' ? result.data : Promise.reject(result.message);
};

export const renameFile = async (fileId, newName, oldName, userId, token) => {
    console.log('renameFile called with:', { fileId, newName, oldName, userId });
    const response = await fetchWithToken(`${API_URL}/api/rename-file`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ file_id: fileId, new_name: newName, old_name: oldName, user_id: userId }),
    }, token);

    if (!response.ok) {
        throw new Error('Failed to rename file');
    }

    const result = await response.json();
    return result.status === 'success' ? result.data : Promise.reject(result.message);
};

export const deleteFilePermanent = async (fileIds, fileNames, userId, token) => {
    console.log('deleteFilePermanent called with:', { fileIds, fileNames, userId, token });

    const results = [];
    for (let i = 0; i < fileIds.length; i++) {
        try {
            const response = await fetchWithToken(`${API_URL}/api/delete-file-permanent`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ file_id: fileIds[i], file_name: fileNames[i], user_id: userId }),
            }, token);

            if (!response.ok) {
                throw new Error(`Failed to delete file ${fileNames[i]} permanently`);
            }

            const result = await response.json();
            if (result.status === 'success') {
                results.push({ fileId: fileIds[i], success: true });
            } else {
                results.push({ fileId: fileIds[i], success: false, message: result.message });
            }
        } catch (error) {
            console.error(`Error deleting file ${fileNames[i]}:`, error);
            results.push({ fileId: fileIds[i], success: false, message: error.message });
        }
    }

    return results;
};

export const getUserInfo = async (user_id, token) => {
    try {
        console.log('getUserInfo called with:', { user_id, token });
        const response = await fetchWithToken(`${API_URL}/api/user-info`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_id: user_id }),
        }, token);

        if (!response.ok) {
            throw new Error('Failed to fetch user info');
        }

        const data = await response.json();
        return data.status === 'success' ? data.data : Promise.reject(data.message);
    } catch (error) {
        console.error('Error in getUserInfo:', error.message);
        throw error;
    }
};

export const getUserPreferences = async (userId, token) => {
    try {
        console.log('getUserPreferences called with:', { userId });
        const response = await fetchWithToken(`${API_URL}/api/get-user-preferences`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_id: userId }),
        }, token);

        if (!response.ok) {
            console.error('Failed to fetch user preferences. Status:', response.status);
            throw new Error('Failed to fetch user preferences');
        }

        const data = await response.json();
        console.log("getUserPreferences data", data.data);
        return data.status === 'success' ? data.data : Promise.reject(data.message);
    } catch (error) {
        console.error('Error in getUserPreferences:', error.message);
        throw error;
    }
};

export const saveUserPreferences = async (preferences, token) => {
    try {
        console.log('saveUserPreferences called with:', { preferences });
        const response = await fetchWithToken(`${API_URL}/api/save-user-preferences`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(preferences),
        }, token);

        if (!response.ok) {
            console.error('Failed to save user preferences. Status:', response.status);
            throw new Error('Failed to save user preferences');
        }

        const data = await response.json();
        return data.status === 'success' ? data.data : Promise.reject(data.message);
    } catch (error) {
        console.error('Error in saveUserPreferences:', error.message);
        throw error;
    }
};

export const addFileToProject = async (fileId, projectId, token) => {
    try {
        console.log('addFileToProject called with:', { fileId, projectId });
        const response = await fetchWithToken(`${API_URL}/api/add-file-to-project`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ file_id: fileId, project_id: projectId }),
        }, token);

        if (!response.ok) {
            console.error('Failed to add file to project. Status:', response.status);
            throw new Error('Failed to add file to project');
        }

        const data = await response.json();
        console.log("Updated Project:", data);
        return data.status === 'success' ? data.data : Promise.reject(data.message);
    } catch (error) {
        console.error('Error in addFileToProject:', error.message);
        throw error;
    }
};

export const fetchArtifact = async (file_id, transformationType, sequenceName, restrictionEnzymes, isBackbone, design, token) => {
    console.log('fetchArtifact called with:', { file_id, transformationType, sequenceName, restrictionEnzymes, isBackbone, design });
    const response = await fetchWithToken(`${API_URL}/api/fetch-artifact`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
            file_id: file_id,
            transform_type: transformationType,
            sequence_name: sequenceName,
            restriction_enzymes: restrictionEnzymes,
            is_backbone: isBackbone,
            design: design
        }),
    }, token);

    if (!response.ok) {
        throw new Error('Failed to fetch artifact');
    }
    const result = await response.json();
    console.log("type of result", typeof result);
    console.log("fetchArtifact response:", result.data, "result.status", result["status"]);

    if (result.status === 'success') {
        console.log("Artifact data:", result.data);
        return result.data;
    } else {
        console.error("Error fetching artifact:", result.message);
        throw new Error(result.message || 'Failed to fetch artifact');
    }
};

export const addSampleProject = async (userId, sampleNumber, token) => {
    console.log('addSampleProject called with:', { sampleNumber, userId, token });
    const response = await fetchWithToken(`${API_URL}/api/add-sample-project`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: userId, sample_number: sampleNumber }),
    }, token);

    if (!response.ok) {
        throw new Error('Failed to add sample project');
    }

    const data = await response.json();
    return data.status === 'success' ? data.data : Promise.reject(data.message);
}

export const generateInstructions = async (projectId, override, groupId, token) => {
    console.log('generateInstructions called with:', { projectId, override, groupId });
    try {
        const response = await fetchWithToken(`${API_URL}/api/generate-instructions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                project_id: projectId, 
                override: override,
                group_id: groupId 
            }),
        }, token);

        if (!response.ok) {
            const errorData = await response.json();
            throw {
                type: 'GenerateInstructionsError',
                // message: errorData.message || 'Failed to generate instructions',
                message: "Whoops! Something went wrong. Please email support@labkick.ai as this is a bug.",
                status: response.status
            };
        }

        return response;
    } catch (error) {
        console.error('Error in generateInstructions:', error);
        throw error;
    }
};

export const navigateDesignAPI = async (projectId, designId, direction, token) => {
    console.log('navigateDesignAPI called with:', { projectId, designId, direction });
    const response = await fetchWithToken(`${API_URL}/api/navigate-design`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ project_id: projectId, design_id: designId, direction: direction }),
    }, token);

    if (!response.ok) {
        throw new Error('Failed to navigate design');
    }

    const result = await response.json();
    console.log("navigateDesignAPI response:", result, "result.status", result["status"]);
    return result.status === 'success' ? result.data : Promise.reject(result.message);
};

export const updateFileProperties = async (fileId, updates, userId, token) => {
    console.log('updateFileProperties called with:', { fileId, updates, userId });
    try {
        const response = await fetchWithToken(`${API_URL}/api/update-file-properties`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ file_id: fileId, updates, user_id: userId }),
        }, token);

        if (!response.ok) {
            throw new Error('Failed to update file properties');
        }

        const result = await response.json();
        return result.status === 'success' ? result.data : Promise.reject(result.message);
    } catch (error) {
        console.error('Error in updateFileProperties:', error);
        throw error;
    }
};

export const createCheckoutSession = async (token, userId, quantity) => {
    const response = await fetchWithToken(`${API_URL}/api/create-checkout-session`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: userId, quantity: quantity }),
    }, token);

    if (!response.ok) {
        throw new Error('Failed to create checkout session');
    }

    const result = await response.json();
    console.log("createCheckoutSession response", result);

    return result.status === 'success' ? result.data : Promise.reject(result.message);
};

export const fetchUsageCredits = async (userId, token) => {
    console.log('fetchUsageCredits called with:', { userId });
    try {
        const response = await fetchWithToken(`${API_URL}/api/fetch-usage-credits`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_id: userId }),
        }, token);

        if (!response.ok) {
            throw new Error('Failed to fetch usage credits');
        }

        const result = await response.json();
        console.log("fetchUsageCredits result", result);
        return result.status === 'success' ? result.data.credits : Promise.reject(result.message);
    } catch (error) {
        console.error('Error in fetchUsageCredits:', error);
        throw error;
    }
};

export const submitArtifactInputsAPI = async (inputs, projectId, token) => {
  console.log('submitArtifactInputsAPI called with:', { inputs, projectId });
  const response = await fetchWithToken(`${API_URL}/api/submit-artifact-inputs`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ inputs, project_id: projectId }),
  }, token);

  if (!response.ok) {
    throw new Error('Failed to submit artifact inputs');
  }

  const result = await response.json();
  return result.status === 'success' ? result.data : Promise.reject(result.message);
};

export const editProtocol = async (fileId, inputs, token) => {
    console.log('editProtocol called with:', { fileId, inputs });
    
    // Convert checkbox values to explicit boolean values
    const processedInputs = Object.fromEntries(
        Object.entries(inputs).map(([key, value]) => {
            if (typeof value === 'boolean') {
                return [key, value];
            } else if (value === 'true' || value === 'false') {
                return [key, value === 'true'];
            }
            return [key, value];
        })
    );

    const response = await fetchWithToken(`${API_URL}/api/edit-protocol`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
            file_id: fileId, 
            inputs: processedInputs 
        }),
    }, token);

    if (!response.ok) {
        throw new Error('Failed to edit protocol');
    }

    const result = await response.json();
    return result.status === 'success' ? result.data : Promise.reject(result.message);
};

export const fetchUsageGroups = async (userId, token) => {
    console.log('fetchUsageGroups called with:', { userId });
    try {
        const response = await fetchWithToken(`${API_URL}/api/read-usage-groups`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_id: userId }),
        }, token);

        if (!response.ok) {
            throw new Error('Failed to fetch usage groups');
        }

        const result = await response.json();
        return result.status === 'success' ? result.data : Promise.reject(result.message);
    } catch (error) {
        console.error('Error in fetchUsageGroups:', error);
        throw error;
    }
};

export const createGroup = async (groupName, userId, token) => {
    console.log('createGroup called with:', { groupName, userId });
    try {
        const response = await fetchWithToken(`${API_URL}/api/create-group`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                name: groupName,
                user_id: userId 
            }),
        }, token);

        if (!response.ok) {
            throw new Error('Failed to create group');
        }

        const result = await response.json();
        console.log("createGroup result", result);
        return result.status === 'success' ? result.data : Promise.reject(result.message);
    } catch (error) {
        console.error('Error in createGroup:', error);
        throw error;
    }
};

export const addGroupMember = async (groupId, email, ownerId, token) => {
    console.log('addGroupMember API called with:', { groupId, email, ownerId });
    try {
        const response = await fetchWithToken(`${API_URL}/api/add-user-to-group`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                group_id: groupId, 
                owner_id: ownerId,
                user_email: email 
            }),
        }, token);

        if (!response.ok) throw new Error('Failed to add group member. Please ensure the user has already signed up with the provided email address.');
        const result = await response.json();
        console.log('addGroupMember API response:', result);
        return result.status === 'success' ? result.data : Promise.reject(result.message);
    } catch (error) {
        console.error('Error in addGroupMember:', error);
        throw error;
    }
};

export const deleteGroup = async (groupId, userId, token) => {
    console.log('deleteGroup API called with:', { groupId, userId });
    try {
        const response = await fetchWithToken(`${API_URL}/api/delete-group`, {
            method: 'POST',
            body: JSON.stringify({ group_id: groupId, user_id: userId }),
        }, token);

        if (!response.ok) throw new Error('Failed to delete group');
        const result = await response.json();
        console.log('deleteGroup API response:', result);
        return result.status === 'success' ? result.data : Promise.reject(result.message);
    } catch (error) {
        console.error('Error in deleteGroup:', error);
        throw error;
    }
};

export const removeGroupMember = async (groupId, ownerId, userEmail, token) => {
    console.log('removeGroupMember API called with:', { groupId, ownerId, userEmail });
    try {
        const response = await fetchWithToken(`${API_URL}/api/remove-user-from-group`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                group_id: groupId, 
                owner_id: ownerId, 
                user_email: userEmail 
            }),
        }, token);

        if (!response.ok) throw new Error('Failed to remove group member');
        const result = await response.json();
        console.log('removeGroupMember API response:', result);
        return result.status === 'success' ? result.data : Promise.reject(result.message);
    } catch (error) {
        console.error('Error in removeGroupMember:', error);
        throw error;
    }
};

export const changeGroupOwner = async (groupId, ownerId, newOwnerEmail, token) => {
    console.log('changeGroupOwner API called with:', { groupId, ownerId, newOwnerEmail });
    try {
        const response = await fetchWithToken(`${API_URL}/api/change-group-ownership`, {
            method: 'POST',
            body: JSON.stringify({
                group_id: groupId,
                owner_id: ownerId,
                new_owner_email: newOwnerEmail
            }),
        }, token);

        if (!response.ok) throw new Error('Failed to change group owner');
        const result = await response.json();
        console.log('changeGroupOwner API response:', result);
        return result.status === 'success' ? result.data : Promise.reject(result.message);
    } catch (error) {
        console.error('Error in changeGroupOwner:', error);
        throw error;
    }
};

export const transferCreditsToGroup = async (groupId, ownerId, amount, token) => {
    console.log('transferCreditsToGroup API called with:', { groupId, ownerId, amount });
    try {
        const response = await fetchWithToken(`${API_URL}/api/transfer-credits-to-group`, {
            method: 'POST',
            body: JSON.stringify({
                group_id: groupId,
                owner_id: ownerId,
                amount: amount
            }),
        }, token);

        if (!response.ok) throw new Error('Failed to transfer credits to group');
        const result = await response.json();
        console.log('transferCreditsToGroup API response:', result);
        return result.status === 'success' ? result.data : Promise.reject(result.message);
    } catch (error) {
        console.error('Error in transferCreditsToGroup:', error);
        throw error;
    }
};

export const transferCreditsFromGroup = async (groupId, ownerId, amount, token) => {
    console.log('transferCreditsFromGroup API called with:', { groupId, ownerId, amount });
    try {
        const response = await fetchWithToken(`${API_URL}/api/transfer-credits-from-group`, {
            method: 'POST',
            body: JSON.stringify({
                group_id: groupId,
                owner_id: ownerId,
                amount: amount
            }),
        }, token);

        if (!response.ok) throw new Error('Failed to transfer credits from group');
        const result = await response.json();
        console.log('transferCreditsFromGroup API response:', result);
        return result.status === 'success' ? result.data : Promise.reject(result.message);
    } catch (error) {
        console.error('Error in transferCreditsFromGroup:', error);
        throw error;
    }
};

export const renameGroup = async (groupId, ownerId, newName, token) => {
    console.log('renameGroup API called with:', { groupId, ownerId, newName });
    try {
        const response = await fetchWithToken(`${API_URL}/api/rename-group`, {
            method: 'POST',
            body: JSON.stringify({
                group_id: groupId,
                owner_id: ownerId,
                new_name: newName
            }),
        }, token);

        if (!response.ok) throw new Error('Failed to rename group');
        const result = await response.json();
        console.log('renameGroup API response:', result);
        return result.status === 'success' ? result.data : Promise.reject(result.message);
    } catch (error) {
        console.error('Error in renameGroup:', error);
        throw error;
    }
};

export const leaveGroup = async (groupId, userEmail, token) => {
    console.log('leaveGroup API called with:', { groupId, userEmail });
    try {
        const response = await fetchWithToken(`${API_URL}/api/remove-user-from-group`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                group_id: groupId,
                owner_id: null,  // Not needed when user is leaving
                user_email: userEmail 
            }),
        }, token);

        if (!response.ok) throw new Error('Failed to leave group');
        const result = await response.json();
        console.log('leaveGroup API response:', result);
        return result.status === 'success' ? result.data : Promise.reject(result.message);
    } catch (error) {
        console.error('Error in leaveGroup:', error);
        throw error;
    }
};

export const resendVerificationEmail = async (userData, token) => {
    console.log('resendVerificationEmail called with:', { userData });
    try {
        const response = await fetchWithToken(`${API_URL}/api/resend-verification`, {
            method: 'POST',
            body: JSON.stringify({
                user_id: userData.sub,
                client_id: "wIR2ovSzlTWy30MYdyeKmsHsFnjcMMds",
                identity: {
                    user_id: userData.sub,
                    provider: userData.sub.split("|")[0]
                }
            }),
        }, token);

        if (!response.ok) {
            throw new Error('Failed to resend verification email');
        }

        return await response.text();
    } catch (error) {
        console.error('Error in resendVerificationEmail:', error);
        throw error;
    }
};

export const fetchOrderData = async (fileIds, projectId, token) => {
    console.log('fetchOrderData called with:', { fileIds, projectId });
    
    const response = await fetchWithToken(`${API_URL}/api/fetch-order-data`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
            file_ids: fileIds,
            project_id: projectId 
        }),
    }, token);

    if (!response.ok) {
        throw new Error('Failed to fetch order data');
    }

    const result = await response.json();
    return result.status === 'success' ? result.data : Promise.reject(result.message);
};

export const submitOrderRequest = async (projectId, orderData, sequenceVerify, token) => {
    console.log('submitOrderRequest called with:', { projectId, orderData, sequenceVerify });
    
    const response = await fetchWithToken(`${API_URL}/api/submit-order-request`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
            project_id: projectId,
            order_data: orderData,
            sequence_verify: sequenceVerify
        }),
    }, token);

    if (!response.ok) {
        throw new Error('Failed to submit order request');
    }

    const result = await response.json();
    return result.status === 'success' ? result.data : Promise.reject(result.message);
};

export const checkDesignUsageLimitAPI = async (projectId, token) => {
    console.log('checkDesignUsageLimitAPI called with:', { projectId });
    try {
        const response = await fetchWithToken(`${API_URL}/api/check-design-usage-limit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ project_id: projectId }),
        }, token);

        if (!response.ok) {
            throw new Error('Failed to check design usage limit');
        }

        const result = await response.json();
        console.log('checkDesignUsageLimitAPI result', result);
        
        // Parse the credits string if it's a string
        const credits = typeof result.data.credits === 'string' 
            ? JSON.parse(result.data.credits) 
            : result.data.credits;
            
        return result.status === 'success' ? credits : Promise.reject(result.message);
    } catch (error) {
        console.error('Error in checkDesignUsageLimitAPI:', error);
        throw error;
    }
};